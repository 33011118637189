import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../scss/video.scss";
import "../../scss/responsive/video.scss";
import iconSerch from "../../images/iconSerch.png";
import axios from "axios";

import Readmore from "../modules/Readmore";

const instance = axios.create({
    withCredentials: true,
});

const VideoPlayer = () => {
    const { t, i18n } = useTranslation();

    let url = process.env.REACT_APP_BASE_URL;
    const [itemsToShow, setItemsToShow] = useState(3);
    const [isReadMore, setIsReadMore] = useState(true);

    const [video, setVideo] = useState([]);
    const [q, setQ] = useState("");
    const [searchTerm] = useState(["title"]);

    const fetchPosts = async (lng) => {
        let lang = lng.slice(0, 2);

        await instance
            .get(`${url}/abio/public/getAllVideos`, {
                params: {
                    language: lang,
                },
            })
            .then((res) => setVideo(res.data))
            .catch((er) => console.log(er));
    };

    useEffect(() => {
        fetchPosts(i18n.language);
    }, [i18n.language]);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const [seeMore, setSeeMore] = useState(true);
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const showmore = () => {
        setItemsToShow(video.length);
    };

    const showless = () => {
        setItemsToShow(3);
    };

    function searchprod(items) {
        return items.filter((item) => {
            return searchTerm.some((newItem) => {
                return (
                    item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    //filtr video data
    // const fire = searchprod(video);
    //
    // const parseDate = (dateString) => {
    //     console.log("Original dateString:", dateString);
    //     const [day, month, year] = dateString.split(/[.\s]/);
    //     console.log("Parsed date components:", day, month, year);
    //     return (new Date(`${year}-${month}-${day}`)).getTime();
    // };
    //
    //
    // fire.sort((a, b) => {
    //     const dateA = parseDate(a.date);
    //     const dateB = parseDate(b.date);
    //     return dateB - dateA;
    // });
    // const fire = searchprod(video);

    // const parseDate = (dateString) => {
    //     console.log("Original dateString:", dateString);
    //     const [day, month, year] = dateString.split(/[.\s]/);
    //     console.log("Parsed date components:", day, month, year);
    //     // Ensure proper formatting of day and month (prepend '0' if single digit)
    //     const formattedDay = day.length === 1 ? '0' + day : day;
    //     const formattedMonth = month.length === 1 ? '0' + month : month;
    //     return (new Date(`${year}-${formattedMonth}-${formattedDay}`)).getTime();
    // };
    const fire = searchprod(video);

    const parseDate = (dateString) => {
        let day, month, year;

        if (dateString.includes('.')) {
            [day, month, year] = dateString.split('.');
        } else if (dateString.includes('․')) {
            [day, month, year] = dateString.split('․');
        } else {
            console.error("Unsupported date format:", dateString);
            return NaN;
        }
        return (new Date(`${year}-${month}-${day}`)).getTime();
    };

    fire.sort((a, b) => {
        const dateA = parseDate(a.date);
        const dateB = parseDate(b.date);
        return dateB - dateA;
    });

        return (
        <>
            <div className="videos">
                <div className="container">
                    <div className="gift__top">
                        <p>
                            {t("Home.home")}
                            <span className="video__top-green">{t("mainMenu.video")}</span>
                        </p>
                    </div>
                </div>
                <div className="video__container">
                    <div className="container">
                        <div className="video__serch">
                            <img
                                src={iconSerch}
                                alt="serch"
                                className="video__serch-icon"
                                onClick={fire}
                            />
                            <input
                                type="text"
                                placeholder={t("homePage.search")}
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </div>
                        <div className="min__containerVideo">
                            <p className="allVideo">{t("Home.allvideo")}</p>
                            {fire.slice(0, itemsToShow).map((item, index) => (
                                <Readmore key={item.id} item={item} />
                            ))}
                            {itemsToShow === 3 ? (
                                <button className="video__btn" onClick={showmore}>
                                    {t("Home.showMore")}
                                </button>
                            ) : (
                                <button className="video__btn" onClick={showless}>
                                    {t("Home.showLess")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { VideoPlayer };
