import row from "../../images/row.png"
import down from "../../images/down.png"
import "../../scss/catalog.scss"
import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next";
import dropdownWhite from "../../images/downWhite.svg"
import topWhiteClose from "../../images/topWhite.svg"
import topBlack from "../../images/topBlack.svg"
import topWhite from "../../images/whiteDown.svg"
import MenuCatalog  from "../header/pages/MenuCatalog";
import { Link } from "react-router-dom";
import axios from "axios";

const instance = axios.create({
    withCredentials: true
  })
const Catalog=()=>{
    const {t}=useTranslation()

    const [isOpenCatalog,setIsOpenColor]=useState(true)


    let url = process.env.REACT_APP_BASE_URL;
    const [posts, setPost] = useState([])
    const {i18n } = useTranslation();
  const fetchPosts = async (lng) => {
    let lang = lng.slice(0, 2)

    await instance.get(`${url}/abio/public/get/catalogs?`,
        {
            params: {
                language: lang
            }
        }
        )
        .then(res => setPost(res.data))
        .catch(er => console.log(er))
}

useEffect(() => {
    fetchPosts(i18n.language)

}, [i18n.language]);

    return(

        <>
        <div >
        <div className="catalog" onClick={()=>setIsOpenColor(!isOpenCatalog)}>
       <p className="catalog-name"> {t("mainMenu.catalog1")}</p>
        <img src={down} alt="down" className="down" />
        <img src={topWhite} alt="dropdownWhite" className="dropdownWhite" />
        </div>
        <img src={row} className="row-img" alt="" />
        </div>

        <div className={isOpenCatalog?"category1":"nocategory"}>


        <ul className="nocategory">
        {posts && posts.map( (menu,index)=>{

const depthLevel = 0;
return < MenuCatalog items={menu} key={index} depthLevel={depthLevel}   handleActive={()=>setIsOpenColor(!isOpenCatalog)} />;
})}
</ul>

        </div>
        </>
    )
}
export {Catalog}