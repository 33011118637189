import Routing from "./container/routes";

const App = () => {

 return(
        <>
          <Routing  />
        </>
    )
}

export default App;
