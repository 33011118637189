import {useState, useEffect, useCallback, useMemo, useRef} from "react";
import '../../scss/tabs.scss'
import check from '../../images/check.png'
import close from '../../images/close.png'
import tellcellimg from '../../images/tellcell.svg'
import DeliverInfo from '../config/DeliverInfo'
import myAmeriaPay from '../../images/MyAmeriaPayMain.svg'
import ReactGA from 'react-ga';
import bankCard from '../../images/bankCard.png'
import master from '../../images/masterCard.jpg'
import idram2 from '../../images/idram2.png'
import pay from '../../images/pay.png'
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {useLocation, useNavigate} from "react-router-dom"
import {Link, useParams} from "react-router-dom"

import Modal from 'react-modal';
import axios from 'axios'
import {makeStyles} from '@material-ui/core/styles';
import {Cookies} from 'react-cookie';

const instance = axios.create({
    withCredentials: true
})

const useStyles = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: "#113E2E"
        },
        "& .MuiOutlinedInput-input": {
            color: "#113E2E"
        },

        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#113E2E"
            }
        }
    }
});
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
Modal.setAppElement('#root');

const





    Tabs = () => {
    const navigate = useNavigate()

    const location = useLocation();
    const {t, i18n} = useTranslation()

    const [toggleState, setToggleState] = useState(1);
    const [total, setTotal] = useState([])
    const [filterRadio, setFilterRadio] = useState('')

    const classes = useStyles();
    const {success} = useParams()
    const {fail} = useParams()
    const [paymentId, setPaymentId] = useState('')
    const [ameria, openAmeriaButton] = useState(true)
    const [ameriaopen, setAmeriaOpen] = useState(false)

    const [cartnum, setCartnum] = useState();
    const initialValue = dayjs('10/05/2023');
    const [value1, setValue1] = useState(initialValue);

    const [modalIsOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [accordion, setAccordion] = useState(null)
    const [token, setToken] = useState('')

    const [bookmarks, setBookmarks] = useState([])


    const [showResults, setShowResults] = useState('')

    const [selected, setSelect] = useState(false)


    const [lezu, setlezu] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [globPrice, setGlobPrice] = useState('')

    const [orderId, setOrderId] = useState([])

    const [cashorder, setCashorder] = useState('')
    const [cashmail, setCashmail] = useState('')


    const [resPrice, setResPrice] = useState('')
    const [resId, setResId] = useState('')
    const [idram, setidram] = useState([])
    const [openButton, setOpenButton] = useState(true)
    const [someId, setsomeId] = useState('')
    const [ameriaId, setAmeriaId] = useState('')

    const [tellId, setTellId] = useState('')
    const [ameria_pay, setAmeria_pay] = useState([])

    const [tellcell, setTellcell] = useState([])
    const [opentellButton, setOpentellButton] = useState(true)
    const [discount, setDescount] = useState(0)
    const [all, setAll] = useState('')

    let url = process.env.REACT_APP_BASE_URL;


    let subtitle;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    function openModal() {
        navigate({
            pathname: '/success',
        })
        setIsOpen(true);
    }

    const toggleTab = (index) => {
        setToggleState(index);
    };

    function afterOpenModal() {

        subtitle.style.color = '#0E0E0E';
        subtitle.style.fontSize = '24px';
        subtitle.style.textAlign = 'center';

    }

    function closeModal() {
        navigate({
            pathname: '/tabs',
        })
        setIsOpen(false);
    }


    const toggleaccord = (index) => {

        setAccordion(index);
        setIsActive(!isActive)


    };


    const getChildData = (val) => {

        setToken(val)
    }

    const handlefilter = (event) => {
        setFilterRadio(event.target.value);


    };


    let getActiveCountry = JSON.parse(localStorage.getItem('lng'))
    useEffect(() => {
        getActiveCountry?.filter((item) => {
            if (item.active) {
                return setlezu(item.name)
            }

        })

    }, [i18n.language])


    const cookies = new Cookies();
    const fetchHeader = useCallback(async () => {

        await instance.post(`${url}/abio/public/cart/getCart`, {}, {
            headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
        })
            .then(async response => {

                setBookmarks(await response.data)
                setGlobPrice(await response.data.globalPrice)
                setDescount(response.data.globalNormalPrice - response.data.globalDiscountPrice)
                // setAll(await response.data.globalNormalPrice + response.data.deliveryPrice)
                setAll(await response.data.globalDiscountPrice)

                // setSubmitting(await response.data.cartProductDTOList.length)

            })
            .catch(er => console.log(er));
    }, [bookmarks, globPrice, all])

    const onRemove = async (prod) => {
        setSubmitting(!submitting)
        await instance.post(`${url}/abio/public/cart/deleteProduct`, {},
            {
                headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
                params: {
                    productCode: prod
                }
            })
            .catch(er => console.log(er));

    };

    let remember = useRef([])
    remember.current = bookmarks?.cartProductDTOList?.length


    useEffect(() => {


        fetchHeader()


    }, [remember.current, submitting, token]);


    const {
        register,
        handleSubmit,

        reset,

        formState: {errors}
    } = useForm()

    const onSubmit1 = async () => {
        ReactGA.event({
            category: "Button",
            action: 'click',
            label: 'Cash'
        });

        let lang = i18n.language.slice(0, 2);
        await instance.post(`${url}/abio/public/cart/confirm?paymentType=CASH&language=${lang}`, {}, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
        })
            .then(el => {
                setCashorder(el.data.id);
                setCashmail(el.data.email);
                setIsOpen(true);

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    'event': 'purchase',
                    'ecommerce': {
                        'items': el.data.cartProductDTOList.map((product, index) => ({
                            'item_name': product.name_en,
                            'item_id': product.productCode,
                            'price': product.price,
                            'item_list_name': 'cart',
                            'index': index + 1,
                            'quantity': product.quantity
                        }))
                    }
                });
            })
            .catch(er => console.log(er));
    };

    const onSubmit4 = async () => {
        openAmeriaButton(!ameria);
        let lang = i18n.language.slice(0, 2);

        await instance.post(`${url}/abio/public/cart/confirm?paymentType=AMERIA&language=${lang}`, {}, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
        })
            .then(el => {
                setPaymentId(el.data.ameria_dto.paymentID);
                setAmeriaOpen(true);

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    'event': 'purchase',
                    'ecommerce': {
                        'items': el.data.cartProductDTOList.map((product, index) => ({
                            'item_name': product.name_en,
                            'item_id': product.productCode,
                            'price': product.price,
                            'item_list_name': 'cart',
                            'index': index + 1,
                            'quantity': product.quantity
                        }))
                    }
                });
            });
    };

    if (ameriaopen) {
        let lang = i18n.language.slice(0, 2);
        window.open(`https://services.ameriabank.am/VPOS/Payments/Pay?id=${paymentId}&lang=@${lang}`, "_self");
        setAmeriaOpen(false);
    }

    const onSubmit2 = async () => {
        setOpenButton(!openButton);
        let lang = i18n.language.slice(0, 2);
        await instance.post(`${url}/abio/public/cart/confirm?paymentType=IDRAM&language=${lang}`, {}, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
        })
            .then(el => {
                setidram(el.data.idram_dto);
                setResId(el.data.id);
                setResPrice(el.data.total_price);
            });
    };

    const onSubmit3 = async () => {
        setOpentellButton(!opentellButton);
        let lang = i18n.language.slice(0, 2);
        await instance.post(`${url}/abio/public/cart/confirm?paymentType=TELLCELL&language=${lang}`, {}, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
        })
            .then(el => {
                setTellcell(el.data.tellcell_dto);
            });
    };

    // const onSubmit1 = async () => {
    //
    //     ReactGA.event({
    //         category: "Button",
    //         action: 'click',
    //         label: 'Cash'
    //     })
    //
    //
    //     let lang = i18n.language.slice(0, 2)
    //     await instance.post(`${url}/abio/public/cart/confirm?paymentType=CASH&language=${lang}`, {}, {
    //         headers: {
    //             'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
    //             'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
    //         },
    //     })
    //         .then(el => {
    //             setCashorder(el.data.id)
    //             setCashmail(el.data.email)
    //             setIsOpen(true);
    //         })
    // };
    //
    // const onSubmit4 = async () => {
    //     openAmeriaButton(!ameria)
    //     let lang = i18n.language.slice(0, 2)
    //
    //
    //     await instance.post(`${url}/abio/public/cart/confirm?paymentType=AMERIA&language=${lang}`, {}, {
    //         headers: {
    //             'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
    //             'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
    //         },
    //     })
    //         .then(el => {
    //             setPaymentId(el.data.ameria_dto.paymentID)
    //             setAmeriaOpen(true);
    //
    //         })
    //
    // };
    //
    // if (ameriaopen) {
    //     let lang = i18n.language.slice(0, 2)
    //     window.open(`https://services.ameriabank.am/VPOS/Payments/Pay?id=${paymentId}&lang=@${lang}`, "_self");
    //
    //     setAmeriaOpen(false);
    // }
    //
    //
    // const onSubmit2 = async () => {
    //     setOpenButton(!openButton)
    //     let lang = i18n.language.slice(0, 2)
    //     await instance.post(`${url}/abio/public/cart/confirm?paymentType=IDRAM&language=${lang}`, {}, {
    //         headers: {
    //             'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
    //             'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
    //         },
    //     })
    //         .then(el => {
    //             setidram(el.data.idram_dto)
    //             setResId(el.data.id)
    //             setResPrice(el.data.total_price)
    //         })
    // }
    // const onSubmit3 = async () => {
    //     setOpentellButton(!opentellButton)
    //     let lang = i18n.language.slice(0, 2)
    //     await instance.post(`${url}/abio/public/cart/confirm?paymentType=TELLCELL&language=${lang}`, {}, {
    //         headers: {
    //             'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
    //             'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
    //         },
    //     })
    //         .then(el => {
    //             setTellcell(el.data.tellcell_dto)
    //         })
    // }


    const handleId = (e) => {
        setsomeId(e.target.name)
    }
    const handletelid = (e) => {
        setTellId(e.target.name)
    }
    const handleAmeriaID = (e) => {
        setAmeriaId(e.target.name)
    }

    const removePromo = async (prom) => {

        await instance.post(`${url}/abio/public/cart/removePromocode`, {}, {
                headers: {
                    'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                    'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
                },
                params: {
                    promoCode: prom
                }
            }
        ).then(res => {
            setBookmarks(res.data)
            setDescount(0)
        })
            .catch(er => console.log(er))

    };

    const Change = async (prod, qu) => {

        if (qu <= 0) {
            qu = 1;
        }

        await instance.post(`${url}/abio/public/cart/updateProduct`, {},
            {
                headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
                params: {
                    productCode: prod,
                    quantity: qu
                }
            })
            .then(el => setBookmarks(el.data.quantity))
            .catch(er => console.log(er));

    };


    const PromoCode = async () => {

        await instance.post(`${url}/abio/public/cart/applyPromocode?promoCode=${showResults}`, {},
            {
                headers: {
                    'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                    'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
                },
            })
            .then(res => {
                    setBookmarks(res.data)
                    setDescount(res.data.globalNormalPrice - res.data.globalDiscountPrice)
                }
            )
            .catch(er => console.log(er))
    }

    const DelRegion = async () => {

        await instance.post(`${url}/abio/public/cart/removeDeliveryRegion`, {},
            {headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},})

            .catch(er => console.log(er))
    }

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });

        if (toggleState === 1) {
            window.dataLayer.push({
                'event': 'view_cart',
                'ecommerce': {
                    'items': bookmarks?.cartProductDTOList?.map((item) => ({
                        'item_name': item.name_en,
                        'item_id': item.productCode,
                        'price': item.price,
                        'item_variant': item.color,
                        'quantity': item.quantity,
                        'discount': item.discountPrice,
                    }))
                }
            });
        } else if (toggleState === 2) {
            window.dataLayer.push({
                'event': 'begin_checkout',
                'ecommerce': {
                    'items': bookmarks?.cartProductDTOList?.map((item) => ({
                        'item_name': item.name_en,
                        'item_id': item.productCode,
                        'price': item.price,
                        'item_variant': item.color,
                        'quantity': item.quantity,
                        'discount': item.discountPrice,
                    }))
                }
            });
        } else if (toggleState === 3) {
            window.dataLayer.push({
                'event': 'add_payment_info',
                'ecommerce': {
                    'items': bookmarks?.cartProductDTOList?.map((item) => ({
                        'item_name': item.name_en,
                        'item_id': item.productCode,
                        'price': item.price,
                        'item_variant': item.color,
                        'quantity': item.quantity,
                        'discount': item.discountPrice,
                    }))
                }
            });
        }
    }, [toggleState, bookmarks]);



    useEffect(() => {
        DelRegion()


    }, []);


    const onblur = () => {
        setSelect(!selected)
    }
    const getInput = (e) => {
        setShowResults(e.target.value);
    };


    const onfocus = () => {
        setSelect(selected)
    }





    const handlePayClick = async () => {
        setOpentellButton(!opentellButton);
        let lang = i18n.language.slice(0, 2);
        await instance.post(`${url}/abio/public/cart/confirm?paymentType=MY_AMERIA&language=${lang}`, {}, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
        })
            .then(el => {
                setAmeria_pay(el.data.my_ameria_dto);
                setResId(el.data.id);
                setResPrice(el.data.total_price);

                const paymentUrl = `https://app.myameria.am/pay/getpaymentoptions?merchantName=${el.data.my_ameria_dto.merchantName}&transactionAmount=${el.data.total_price}&transactionId=${el.data.my_ameria_dto.transactionId}&merchantId=${el.data.my_ameria_dto.merchantId}&terminalId=${el.data.my_ameria_dto.terminalId}&callbackscheme=abio.am`;

                window.open(paymentUrl, "_self");
            });
    };
    return (
        <>
            <div className="continner">
                <p className="cardText">{t("cart.yourCart")}</p>
            </div>
            <div className="cont">
                <div className="continner">
                    <div className="bloc-tabs">
                        <button
                            className={toggleState === 1 ? "tabs active-tabs1   " : "tabs  "}
                            onClick={() => toggleTab(1)}
                        >
                            1.{t("cart.yourCart")}
                        </button>
                        <button
                            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)}
                        >
                            2. {t("cart.information")}
                        </button>
                        <button
                            className={toggleState === 3 ? "tabs active-tabs2  " : "tabs "}
                            // onClick={() => toggleTab(3)}
                        >
                            3.{t("cart.payment")}
                        </button>

                        <div className="lineborder"></div>

                    </div>

                    <div className="content-tabs">

                        <div
                            className={toggleState === 1 ? "content  active-content" : "content"}
                        >
                            <div className="cont_text">
                                <div className="first_cont">
                                    <div className="contHeader">


                                        <table>

                                            <thead>
                                            <tr>
                                                <th>{t("cart.code")}</th>
                                                <th>{t("cart.details")} </th>
                                                <th>{t("cart.price")}</th>
                                                <th>{t("cart.quantity")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {bookmarks?.cartProductDTOList?.length > 0 ? bookmarks?.cartProductDTOList?.map((el, i) => {

                                                return (

                                                    <tr key={el.productCode}>
                                                        <td>
                                                            {el.productCode}
                                                        </td>
                                                        <td className="spacial_cont">

                                                            <img
                                                                src={`${url}/abio/public/files?productCode=${el.productCode}&fileName=${el?.picturePath}`}
                                                                alt=""/>
                                                            <div>
                                                                <p className="flaxible">{t("cart.code")}: {el.productCode}</p>


                                                                <p className="changebold">{el?.[`name_${lezu}`]} </p>
                                                                <div className="span_block">
                                                                    {/* {noCart.find((x) =>  el?.productCode === x?.productCode)?.color ? <> <p>Color:</p>

<p className="span"></p></> : null} */}

                                                                    <img src={close}
                                                                         className="closeimg flaxiblemob"
                                                                         onClick={() => onRemove(el.productCode)}
                                                                         alt="close"/>
                                                                </div>
                                                                <p className="flaxible myprice"> {el.discountPrice?.toLocaleString()} AMD</p>
                                                                <div className="spacial_cont flaxiblemob">

                                                                        <span className="card-minus"
                                                                              onClick={() => Change(el.productCode, el.quantity - 1)}>-</span>

                                                                    <p>{el.quantity <= 0 ? el.quantity = 1 : el.quantity}</p>

                                                                    <span className="card-plus"
                                                                          onClick={() => Change(el.productCode, el.quantity + 1)}>+</span>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="forMobile">{el.discountPrice?.toLocaleString()} AMD</td>
                                                        <td className="forMobile">
                                                            <div className="spacial_cont">

                                                                    <span className="card-minus"
                                                                          onClick={() => Change(el.productCode, el.quantity - 1)}>-</span>

                                                                <p>{el.quantity <= 0 ? el.quantity = 1 : el.quantity}</p>

                                                                <span className="card-plus"
                                                                      onClick={() => Change(el.productCode, el.quantity + 1)}>+</span>
                                                                <img src={close} className="closeimg "
                                                                     onClick={() => onRemove(el.productCode)}
                                                                     alt="close"/>
                                                            </div>

                                                        </td>

                                                    </tr>

                                                )

                                            }) : (<p className="noTable">{t("cart.noItem")}</p>)}


                                            </tbody>

                                        </table>


                                        <div className="payleft">
                                            <div className="paytitle">
                                                <p>{t("cart.total")}</p>
                                                <p>{total.productCode} {bookmarks?.globalDiscountPrice?.toLocaleString()} AMD</p>
                                            </div>
                                            <div className="line"></div>
                                            {bookmarks?.deliveryEnabled ?
                                                <div className="deliver">
                                                    <p>{t("cart.delivery")}:</p>
                                                    <span>{bookmarks?.deliveryPrice?.toLocaleString()} AMD</span>
                                                </div> : null
                                            }

                                            <div className="totalPay">
                                                <p>{t("cart.total")}</p>
                                                <p>{total.price}{bookmarks?.globalPrice?.toLocaleString()} AMD</p>
                                            </div>

                                            <div className="lastbtn">

                                                <button  onClick={() => toggleTab(2)}> {t("cart.next")}</button>

                                            </div>

                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>

                        <div
                            className={toggleState === 2 ? "content  active-content" : "content"}
                        >
                            <DeliverInfo toggleTab={toggleTab} totalPrice={globPrice}
                                         getChildData={getChildData} priceGlob={bookmarks?.globalDiscountPrice}
                                         showpromo={bookmarks?.cartProductDTOList?.[0]?.promocode}/>
                        </div>

                        <div
                            className={toggleState === 3 ? "content  active-content" : "content"}
                        >

                            <div className="contLast">


                                <div>
                                    <div className="cardContent"
                                         onClick={() => toggleaccord(1)}
                                    >
                                        <input type='radio' id="paym"
                                               checked={filterRadio === '10'}
                                               value={10}
                                               onChange={handlefilter}
                                            //  name="paymant"
                                        />
                                        <label htmlFor="paym">
                                            <img src={bankCard} alt="card"/>
                                            <p>{t("cart.bank")}</p>
                                        </label>
                                    </div>


                                    <div className="cardContent" onClick={() => toggleaccord(2)}>

                                        <input type='radio' id="idram"
                                               checked={filterRadio === '20'}

                                               value={20}
                                               onChange={handlefilter}
                                            // name="paymant"
                                        />
                                        <label htmlFor="idram">
                                            <img src={idram2} alt="idram"/>
                                            <p>{t("cart.idram")}</p>
                                        </label>
                                    </div>
                                    <div className={accordion === 2 ? "accord accord-content accord-ss" : "accord"}>

                                        <div className="contCard">

                                            <div>


                                            </div>
                                            {/* <div className="master">
<img src={master}   alt="card" />
</div> */}
                                        </div>


                                    </div>


                                    <div className="cardContent mobile_pay" onClick={() => toggleaccord(5)}>
                                        <input type='radio' id="ameria_pay"
                                               checked={filterRadio === '50'}
                                               value={50}
                                               onChange={handlefilter}
                                        />
                                        <label htmlFor="ameria_pay">
                                            <img src={myAmeriaPay} alt="ameria_pay"/>
                                            <p>{t("cart.ameriaPay")}</p>
                                        </label>
                                    </div>
                                    <div className={accordion === 5 ? "accord accord-content accord-ss" : "accord"}>

                                        <div className="contCard">

                                            <div>


                                            </div>
{/*                                            <div className="master">*/}
{/*<img src={master}   alt="card" />*/}
{/*</div>*/}
                                        </div>


                                    </div>


                                    <div className="cardContent" onClick={() => toggleaccord(4)}>

                                        <input type='radio' id="tellcell"
                                               checked={filterRadio === '40'}

                                               value={40}
                                               onChange={handlefilter}
                                            // name="paymant"
                                        />
                                        <label htmlFor="tellcell">
                                            <img src={tellcellimg} alt="tellcell"/>
                                            <p>{t("cart.tellcell")}</p>
                                        </label>
                                    </div>
                                    {/* <div className={accordion === 4 ? "accord accord-content" : "accord"}>

                                          </div> */}
                                    {bookmarks?.deliveryEnabled ?
                                        <>
                                            <div className="cardContent" onClick={() => toggleaccord(3)}>
                                                <form onSubmit={handleSubmit(onSubmit1)}>
                                                    <label htmlFor="pay">
                                                        <input type='radio' id='pay'
                                                               checked={filterRadio === '30'}
                                                               value={30}
                                                               onChange={handlefilter}
                                                            // name="paymant"
                                                        />

                                                        <img src={pay} alt="pay"/>
                                                        <p>{t("cart.cash")}</p>
                                                    </label>
                                                </form>
                                            </div>
                                            <div className={accordion === 3 ? "accord accord-content" : "accord"}>

                                            </div>
                                        </> : null}
                                </div>

                                <div>
                                    <form id="tellId" target="_blank" action="https://telcellmoney.am/invoices"
                                          method="POST">
                                        <input type="hidden" name="issuer" value={tellcell.issuerField}/>
                                        <input type="hidden" name="action" value={tellcell.actionField}/>
                                        <input type="hidden" name="currency" value={tellcell.currencyField}/>
                                        <input type="hidden" name="price" value={tellcell.priceField}/>
                                        <input type="hidden" name="product" value={tellcell.productField}/>
                                        <input type="hidden" name="issuer_id" value={tellcell.issuer_idField}/>
                                        <input type="hidden" name="valid_days" value={tellcell.valid_daysField}/>
                                        <input type="hidden" name="lang" value={tellcell.langField}/>
                                        <input type="hidden" name="security_code" value={tellcell.security_codeField}/>
                                    </form>

                                </div>


                                <div className="payleft">
                                    <div className="paytitle">
                                        <p>{t("cart.total")}</p>
                                        <p>{total.productCode} {all?.toLocaleString()} AMD</p>
                                    </div>
                                    <div className="line"></div>
                                    {bookmarks?.deliveryEnabled ?
                                        <div className="deliver">
                                            <p>{t("cart.delivery")}: </p>
                                            <span>{bookmarks?.deliveryPrice?.toLocaleString()} AMD</span>

                                        </div> : null
                                    }

                                    <div className="discount">
                                        <p>{t("cart.discount")}: </p>
                                        <span> {discount.toLocaleString()} AMD</span>
                                    </div>
                                    <div className="totalPay">
                                        <p>{t("cart.total")}</p>
                                        <p>{bookmarks?.globalPrice?.toLocaleString()} AMD</p>
                                    </div>
                                    {bookmarks?.deliveryEnabled ?
                                        <div className="promoCode">


                                            <TextField
                                                className={classes.root}
                                                label={t("cart.promo")}
                                                type='search'
                                                id='inputAdornedEnd '
                                                onChange={getInput}


                                                onBlur={onblur}
                                                onFocus={onfocus}
                                                InputProps={{


                                                    className: 'myclass',
                                                    endAdornment: (
                                                        bookmarks?.cartProductDTOList?.[0]?.promoCode == null ?

                                                            (<a className="spanforCheck" id='lav_btn'
                                                                onClick={PromoCode}>{t("cart.apply")}</a>)
                                                            : <div className="checkedprom"><img src={check}/>
                                                                <span>{bookmarks?.cartProductDTOList?.[0].promoCode}</span><img
                                                                    width='15px'
                                                                    onClick={() => removePromo(bookmarks?.cartProductDTOList?.[0].promoCode)}
                                                                    className="hidepromo" src={close}/></div>


                                                    ),
                                                }}

                                            />


                                        </div> : null
                                    }


                                    <div className="lastbtn">

                                        {accordion === 1 &&
                                            <>

                                                <button
                                                    onClick={onSubmit4}
                                                    className="pay_class"
                                                    type="submit"> {t("cart.next")}</button>


                                            </>
                                        }
                                        {accordion === 3 &&
                                            <button onClick={onSubmit1}
                                                    className="pay_class"
                                                    type="submit"> {t("cart.next")}</button>
                                        }
                                        {accordion === 2 &&
                                            <>
                                                {openButton ?
                                                    <button
                                                        onClick={onSubmit2}
                                                        type="submit"> {t("cart.next")}</button> :
                                                    <button
                                                        form={someId}
                                                        name="form1" onClick={handleId}
                                                        className="pay_class"
                                                        type="submit"> {t("cart.idram")}</button>
                                                }


                                            </>
                                        }
                                        {accordion === 4 &&
                                            <>
                                                {opentellButton ?
                                                    <button
                                                        onClick={onSubmit3}
                                                        type="submit"> {t("cart.next")}</button> :
                                                    <button
                                                        form={tellId}
                                                        name="tellId" onClick={handletelid}
                                                        className="pay_class"
                                                        type="submit"> {t("cart.tellcell")}</button>
                                                }

                                                </>
                                        }

                                        {accordion === 5 &&
                                            <>
                                                <button
                                                    onClick={handlePayClick}
                                                    type="submit"> {t("cart.next")}</button>
                                            </>
                                        }

                                    </div>
                                </div>

                            </div>

                            <div>
                                <form id="form1" action="https://banking.idram.am/Payment/GetPayment" method="POST">
                                    <input type="hidden" name="EDP_LANGUAGE" value={idram?.edp_LANGUAGE}/>
                                    <input type="hidden" name="EDP_REC_ACCOUNT" value={idram?.edp_REC_ACCOUNT}/>
                                    <input type="hidden" name="EDP_DESCRIPTION" value={idram?.edp_DESCRIPTION}/>
                                    <input type="hidden" name="EDP_AMOUNT" value={idram?.edp_AMOUNT}/>
                                    <input type="hidden" name="EDP_BILL_NO" value={idram?.edp_BILL_NO}/>
                                    {/* <input type="submit" className="continuShop" value={t("cart.idram")}/> */}
                                </form>
                            </div>

                            <div>
                                <form id="form5" action="https://app.myameria.am/pay/getpaymentoptions" method="GET">
                                    <input type="hidden" name="merchantId" value={ameria_pay?.merchantId}/>
                                    <input type="hidden" name="merchantName" value={ameria_pay?.merchantName}/>
                                    <input type="hidden" name="terminalId" value={ameria_pay?.terminalId}/>
                                    <input type="hidden" name="transactionId" value={resId}/>
                                    <input type="hidden" name="transactionAmount" value={resPrice}/>
                                    <input type="hidden" name="callbackscheme" value="www.abio.am"/>
                                </form>
                            </div>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                ariaHideApp={false}
                                style={customStyles}

                            >
                                <div className='modalCont'>
                                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}><input type="checkbox"
                                                                                           className="modalcheck"
                                                                                           checked/>{t("Tabs.thanks")}
                                    </h2>
                                    <button className="closeModal" onClick={closeModal}><img alt="image" src={close}/>
                                    </button>
                                </div>
                                <div className="modalText" id="pay_ids">{t("Tabs.thanksdetail1")}
                                    <span> {cashorder} </span>
                                    {t("Tabs.thanksdetail2")}
                                    <span>{cashmail}</span></div>
                                <Link to={`/${i18n.language}`}>
                                    <button className="continuShop">{t("shop.continue")}</button>
                                </Link>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export {Tabs};