import React, {useEffect, useState,memo, useRef} from "react";
import dayjs, {Dayjs} from 'dayjs';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import FormHelperText from '@mui/material/FormHelperText';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import check from '../../images/check.png'
import close from '../../images/close.png'
import {useForm, Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import axios from "axios";
import {makeStyles} from '@material-ui/core/styles';
import {Cookies} from 'react-cookie';


const instance = axios.create({
    withCredentials: true
})

const useStyles = makeStyles({
    root: {

        "& label.Mui-focused": {
            color: "#113E2E"
        },
        "& .MuiOutlinedInput-input": {
            color: "#113E2E"
        },


        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#113E2E"
            }
        }
    }
});



function DeliverInfo({toggleTab, priceGlob, totalPrice, showpromo, getChildData}) {
    const {t, i18n} = useTranslation()
    const [some, setSome] = useState([])
    const [region, setRegion] = useState([])
    const [selected, setSelect] = useState(false)
    const today = dayjs();
    const initialValue = dayjs(Date.now());

    const [value, setValue] = useState(initialValue);
    const [selectedCantry, setselectedCantry] = useState('');
    const [selectTime, setselectTime] = useState('');
    const navigate = useNavigate();
    const [showResults, setShowResults] = useState('')
    const [deliveryPrice, setDeliverPrice] = useState('')


    let url = process.env.REACT_APP_BASE_URL;

    const [lezu, setlezu] = useState('')
    const [token, setToken] = useState('')
    const [phone, setPhone] = useState(374)
    const handlePhoneCh = event => {
        const result = event.target.value.replace(/\D/g, '');

        setPhone(result);
    };


    // const ALPHA_NUMERIC_DASH_REGEX  = /\p{Letter}/gu;

    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Zա-ֆԱ-Ֆаа-яА-Я\s\-]+$/;

    let getActiveCountry = JSON.parse(localStorage.getItem('lng'))
    useEffect(() => {
        getActiveCountry?.filter((item) => {
            if (item.active) {
                return setlezu(item.name)
            }

        })

    }, [i18n.language])


    

    const handleChange = (event) => {
        setselectedCantry(event.target.value);
    };

    const handletimeChange = (event) => {
        setselectTime(event.target.value);
    };


    const [error, setError] = useState(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchHeader()
        }, 3000);
        return () => clearTimeout(timer);


    }, [some]);

    useEffect(() => {
        deliverPrice()


        cartImage()


    }, [selectedCantry, i18n.language])

    const cartImage = async () => {
        let lang = i18n.language.slice(0, 2)
        await instance.get(`${url}/abio/public/cart/getDeliveryRegions?language=${lang}`, {
            headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
        })
            .then(response => setRegion(response.data))
            .catch(er => console.log(er));
    }


    const removePromo = async (prom) => {

        await instance.post(`${url}/abio/public/cart/removePromocode`, {},
            {
                headers: {
                    'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                    'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
                },
                // params: {
                //     promoCode: prom
                // }
            }
        ).then(res => console.log(res)).catch(er => console.log(er))
            .catch(er => console.log(er));
    };


    const cookies = new Cookies();
    const fetchHeader = async () => {


        await instance.post(`${url}/abio/public/cart/getCart`, {}, {
            headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
        })
            .then(async response => {

                setSome(await response.data)
                setDeliverPrice(await response.data.deliverPrice)
                getChildData(await response.data.globalPrice)

            })
            .catch(er => console.log(er));
    }

    const deliverPrice = async () => {
        if (selectedCantry !== '' && selectedCantry !== null) {
            await instance.post(`${url}/abio/public/cart/addDeliveryRegion`, {},
                {
                    headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
                    params: {
                        deliveryRegionId: selectedCantry
                    }
                }
            ).catch(er => console.log(er));

        }


    };


    const {
        register,
        handleSubmit,
        reset,
        control,

        formState: {errors}
    } = useForm()
      
   

    const onSubmit = async (data) => {
        
        toggleTab(3)
        // reset()

        navigate('');
        const baseurl = `${url}/abio/public/cart/user/initiate`
        await instance.post(baseurl, {...data}, {
            headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
            params: {
                first_name: data.first_name,
                last_name: data.last_name,
                phone_number: data.phone_number,
                email: data.email,
                address: data.address,
                day: data.day,
                time: data.time,
                comment: data.comment
            }
        })
            .then(el => {
                // orderId , globalPrice
                // setOrderId(el.orderId)
            })

    };


    const classes = useStyles();
    const PromoCode = async () => {

        await instance.post(`${url}/abio/public/cart/applyPromocode?promoCode=${showResults}`, {},
            {
                headers: {
                    'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                    'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
                },
            }
        ).catch(er => console.log(er))


    }


    const onblur = () => {
        setSelect(!selected)
    }
    const getInput = (e) => {


        setShowResults(e.target.value);


    };


    const onfocus = () => {
        setSelect(selected)
    }
    const reorderedRegion = [...region]; // создаем копию массива
    const lastItem = reorderedRegion.pop(); // удаляем последний элемент
    reorderedRegion.unshift(lastItem); // добавляем его в начало
    reorderedRegion.pop();
    return (
        <>
            <div className='deliverinfo'>

                <div className='formblock'>
                    <h2>{t("cart.address")} </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="inputBlock">
                                <div className="inputDivs">
                                    <TextField sx={{minWidth: 250}}
                                               variant="outlined"
                                               label={t("cart.name")}
                                               autoComplete="off"
                                               className={classes.root}
                                               onKeyDown={(event) => {
                                                   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                                       event.preventDefault();
                                                   }
                                               }}
                                               {...register("first_name", {required: t("cart.errName")})}
                                               error={!!errors?.first_name}
                                               helperText={errors?.first_name ? errors?.first_name.message : null}


                                    />
                                </div>
                                <div className="inputDivs">
                                    <TextField sx={{minWidth: 250}}
                                               className={classes.root}
                                               label={t("cart.surname")}
                                               variant="outlined"
                                               autoComplete="off"
                                               onKeyDown={(event) => {
                                                   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                                       event.preventDefault();
                                                   }
                                               }}
                                               {...register("last_name", {required: t("cart.errSurname")})}
                                               error={!!errors?.last_name}
                                               helperText={errors?.last_name ? errors?.last_name.message : null}
                                    />
                                </div>

                            </div>

                            <div className="inputBlock">
                                <div className="inputDivs">
                                    <TextField
                                        sx={{minWidth: 250}}
                                        label={t("cart.phone")}
                                        className={classes.root}
                                        variant="outlined"
                                        inputProps={{maxLength: 16, minLength: 12}}
                                        autoComplete="off"
                                        // type="number"
                                        placeholder="+374XXXXXXXX"


                                        {...register("phone_number", {
                                            required: t("cart.errPhone"),
                                            minLength: 11,
                                            pattern: {

                                                value: /^\+?[0-9]{3}-?[0-9]{8,12}$/,
                                                message: 'Please enter number'
                                            }
                                        })}

                                        error={!!errors?.phone_number}
                                        helperText={errors?.phone_number ? errors?.phone_number.message : null}
                                        value={'+' + phone}
                                        onChange={handlePhoneCh}
                                    />
                                </div>
                                <div className="inputDivs">
                                    <TextField
                                        sx={{minWidth: 250}}
                                        className={classes.root}
                                        label={t("cart.email")}
                                        placeholder={t("cart.email")}
                                        variant="outlined"
                                        autoComplete="off"
                                        {...register("email", {
                                                required: t("cart.errEmail"),
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: 'Invalid email type'

                                                }
                                            }
                                        )}
                                        error={!!errors?.email}
                                        helperText={errors?.email ? errors?.email.message : null}/>

                                </div>
                            </div>
                            {some?.deliveryEnabled ?
                                <div className="inputBlock">
                                    <div className="inputDivs">
                                        <FormControl fullWidth className={classes.root} id='citySelect'
                                                     {...register("city", {required: t("cart.errCity")})}
                                        >
                                            <InputLabel>{t("cart.city")} </InputLabel>
                                            <Select sx={{minWidth: 250}}

                                                    value={selectedCantry}
                                                    name="city"
                                                    label={t("cart.city")}
                                                    onChange={handleChange}
                                                    error={!!errors?.address}
                                            >

                                                {reorderedRegion.map((item, i) => {
                                                    return (
                                                        <MenuItem value={item.id} key={i}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}

                                            </Select>
                                            <FormHelperText
                                                error={!!errors?.city}

                                            >{errors?.city ? errors?.city.message : null}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="inputDivs">
                                        <TextField
                                            sx={{minWidth: 250}}
                                            className={classes.root}
                                            label={t("cart.addressName")}
                                            variant="outlined"
                                            autoComplete="off"
                                            {...register("address", {required: t("cart.errAddressName")})}

                                            error={!!errors?.address}
                                            helperText={errors?.address ? errors?.address.message : null}
                                        />
                                    </div>
                                </div> : null}
                            <h2>{t("cart.info")} </h2>
                            <div className="inputBlock">


                                <div className="inputDivs">
                                    {/*
                                        <DatePicker
                                            // mask="10.10.2023"
                                            value={value}
                                            sx={{minWidth: 250}}
                                            defaultValue={today}
                                            disablePast
                                         
                                            //  name = 'day'
                                            className={classes.root}
                                            label={t("cart.day")}
                                          
                                            {...register("day", {required: t("cart.day")})}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                            }}   
                                           
                                           
                                         

                                            showDaysOutsideCurrentMonth
                                            onError={(newError) => setError(newError)}
                                            slotProps={{
                                              textField: {
                                                required: true,
                                                error: !!errors?.day,
                                                helperText: errors?.day ? errors?.day.message : null,
                                              },
                                            }}


                                        //     renderInput={(params) => <TextField  {...params}    
                                        //    />}
                                         
                                        /> */}

                                    <Controller
                                        name="day"
                                        control={control}
                                        // defaultValue={dayjs()}
                                        // value={value || ''}
                                        render={({
                                                     field: {onChange, value},
                                                     // fieldState: { errors }
                                                 }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t("cart.date")}
                                                    // format="DD/MM/YYYY"

                                                    //  defaultValue={dayjs(new Date()) }
                                                    //  value={value || ''}
                                                    className={classes.root}
                                                    control={control}
                                                    disablePast
                                                    showDaysOutsideCurrentMonth
                                                    {...register("day",
                                                        {required: t("cart.errDay")}
                                                    )}
                                                    error={!!errors?.day}
                                                    helperText={errors?.day ? errors?.day.message : null}
                                                    onChange={event => onChange(event.$d?.toLocaleDateString())}
                                                    // slotProps={{ textField: {required: true, error: !!error?.day , helperText: errors?.day ? errors?.day.message : null } }}
                                                />
                                                <FormHelperText
                                                    error={!!errors?.day}

                                                >{errors?.day ? errors?.day.message : null}</FormHelperText>

                                            </LocalizationProvider>
                                        )}
                                    />


                                </div>

                                <div className="inputDivs">
                                    <TextField id="select" label={t("cart.time")} value={selectTime}
                                               sx={{minWidth: 250}}
                                               {...register("time", {required: t("cart.errTime")})}
                                               error={!!errors?.time}
                                               helperText={errors?.time ? errors?.time.message : null}
                                               className={classes.root}
                                               onChange={handletimeChange}
                                               select>
                                        <MenuItem value="11։00-12։00">11:00-12:00</MenuItem>
                                        <MenuItem value="12։00-13։00">12:00-13:00</MenuItem>
                                        <MenuItem value="13։00-14։00">13:00-14:00</MenuItem>
                                        <MenuItem value="14։00-15։00">14:00-15:00</MenuItem>
                                        <MenuItem value="15։00-16։00">15:00-16:00</MenuItem>
                                        <MenuItem value="16։00-17։00">16:00-17:00</MenuItem>
                                        <MenuItem value="17։00-18։00">17:00-18:00</MenuItem>
                                        <MenuItem value="18։00-19։00">18:00-19:00</MenuItem>
                                    </TextField>
                                </div>


                            </div>
                            <div className="inputBlock">
                                <div className="inputDivs">
                                    <TextField sx={{minWidth: 250}}
                                               {...register("comment")}
                                               autoComplete="off"
                                               className={classes.root}
                                               placeholder={t("cart.comment")}
                                               minRows={3}
                                               multiline

                                    />

                                </div>


                            </div>
                        </div>


                        <div className="payleft">
                            <div className="paytitle">
                                <p>{t("cart.total")} </p>
                                <p>{priceGlob?.toLocaleString()} AMD</p>
                            </div>
                            <div className="line"></div>
                            {some?.deliveryEnabled ?
                                <div className="deliver">
                                    <p>{t("cart.delivery")}: </p>
                                    <span> {some?.deliveryPrice?.toLocaleString()} AMD</span>
                                </div> : null
                            }


                            <div className="totalPay">
                                <p>{t("cart.total")} </p>
                                <p>{some?.globalPrice?.toLocaleString()} AMD</p>
                            </div>
                        
                            <div className="lastbtn">

                                <button type="submit">  {t("cart.next")}</button>

                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </>
    )
}

export default memo( DeliverInfo)
