import { useState } from "react"
import { Link,Outlet } from "react-router-dom"
import { Footer } from "../footer/Footer"
import { Navbar } from "../header/Navbar"
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
const Layout=()=>{
    const {t, i18n}=useTranslation()

    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={t("mainMenu.decription")} />
                <title> {t("mainMenu.title")}</title>
                <link rel="canonical" href={t("mainMenu.url")} />
            </Helmet>
        <header>
            <Navbar />
        </header>
        <Outlet />

        <footer>
            <Footer/>
        </footer>
        </>
    )
}

export {Layout}