import React , {useState} from 'react'
import { useCollapse } from 'react-collapsed'
import { useTranslation } from "react-i18next";

function ReadmoreHeader({item}) {
    const {t,i18n}=useTranslation()

    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  return (
<div>
    {item?.headLine && item?.headLine?.length < 35 ? (
        
        <p className="strongTxt-slide">{item?.headLine}</p> 
      
    ) : (
        <p className="strongTxt-slide"   {...getToggleProps({
            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
          })} >
        {item?.headLine?.slice(0, 35) }<span>{isExpanded ?  "" : '...'}</span>
       <span {...getCollapseProps()}>
         {item?.headLine?.slice(35,65)}
       </span> 
       </p> 


)}
  </div>
  )
}

export default ReadmoreHeader