import { Link } from "react-router-dom";
import "../../../scss/menu.scss";
import "../../../scss/responsive/menu.scss";
import down from "../../../images/drop.svg";
import burger from "../../../images/burger.png";
import close from "../../../images/close.png";
import logo from "../../../images/logoabio.png";
import iconCloseSerch from "../../../images/closeSerch.png";
import iconePhone from "../../../images/iconePhone.png";
import catalogdown from "../../../images/catalogdown.svg";
import leftDown from "../../../images/leftDown.svg";
import I from "../../../images/i.png";
import iconSerch from "../../../images/iconSerch.png";
import { useTranslation } from "react-i18next";
import dropdownWhite from "../../../images/white.svg";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import like from "../../../images/like.svg";
import shop from "../../../images/shop.svg";
import { languagesData } from "../../../data/languagesData";
import { shopData } from "../../../data/shopData";
import Menunew from "./Menunew";
import { MenuRespons } from "./MenuRespons";
import { BagPage } from "./BagPage";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";

const instance = axios.create({
    withCredentials: true,
});

const Menu = ({ shopMenu, shoping }) => {
    const navigate = useNavigate();
    const [favoritData, setFavoritData] = useState([]);
    const [q, setQ] = useState("");
    const [searchTerm] = useState(["name"]);
    const [todos, setTodos] = useState([]);
    const [posts, setPost] = useState([]);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    let lang = i18n.language.slice(0, 2);
    let url = process.env.REACT_APP_BASE_URL;
    const fetchCatalogPosts = async (lng) => {
        let lang = lng.slice(0, 2);

        await instance
            .get(`${url}/abio/public/get/catalogs?`, {
                params: {
                    language: lang,
                },
            })
            .then((res) => setPost(res.data))
            .catch((er) => console.log(er));
    };

    useEffect(() => {
        fetchCatalogPosts(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        const allData = JSON.parse(localStorage.getItem("bookmarks")) || [];
        if (allData) setTodos(allData);
    }, [localStorage.getItem("bookmarks")]);



    const clearInput = () => {
        if (q !== null) {
            setQ("");
            navigate(-1);
        } else {
            console.error("The value of 'q' is null.");
        }
    };

    const openprofile = (id) => {
        navigate({ pathname: "search", search: `?${createSearchParams(q)}` });
    };
    const [openBurger, setOpenBurger] = useState(true);
    const [showSerch, setShowSerch] = useState(true);
    const [closeSerch, setCloseSerch] = useState(false);
    const [languages, setLanguages] = useState(languagesData);
    const [showFlag, setShowFlag] = useState(false);
    const [openShop, setOpenShop] = useState(false);
    const [closeMenu, setCloseMenu] = useState(false);
    const switcher = (lng, index) => () => {
        let activeCountry = languages.filter((item) => {
            if (item.name === lng) {
                item.active = true;
            } else {
                item.active = false;
            }
            return item;
        });
        let element = activeCountry[index];
        activeCountry.splice(index, 1);
        activeCountry.splice(0, 0, element);
        setLanguages(activeCountry);
        localStorage.setItem("lng", JSON.stringify(activeCountry));
        setShowFlag(false);
        i18n.changeLanguage(lng);
    };

    function searchChange(event) {
        setCloseSerch(true);
    }

    const onChangeLang = (e) => {
        i18n.changeLanguage(e.target.value);
    };

    const renderLang = () => {
        let activeLanguage = languages.filter((item) => {
            if (item.name === i18n.language) {
                item.active = true;
            } else {
                item.active = false;
            }
            return item;
        });

        setLanguages(activeLanguage);
        localStorage.setItem("lng", JSON.stringify(activeLanguage));
        if (window.location.pathname.includes(`/am`)) {
            i18n.changeLanguage("am");
        }
        if (window.location.pathname.includes(`/en`)) {
            i18n.changeLanguage("en");
        }

        if (window.location.pathname.includes(`/ru`)) {
            i18n.changeLanguage("ru");
        }
    };

    useEffect(() => {
        let getActiveCountry = JSON.parse(localStorage.getItem("lng"));
        if (getActiveCountry) {
            setLanguages(getActiveCountry);
        }
    }, [i18n.language]);

    useEffect(() => {
        renderLang();
    }, [i18n.language]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            openprofile();
        }
    };

    return (
        <>
            <div className="container">
                <nav>
                    <div className="nav__forRes">
                        <div className="nav__forRes-top">
                            <div className="nav-logo">
                                <Link to={`/${i18n.language}`}>
                                    <img src={logo} alt="logo" className="logoImg" />
                                </Link>
                            </div>
                            <div className="phoneForRes">
                                <img src={iconePhone} alt="iconePhone" />
                                <a href="tel:+37499520223">+374 99 520223</a>
                            </div>

                            <div className={showSerch ? "aaaa" : "bbbb"}>
                                <div className={showSerch ? "serch-flag" : "serchResNo"}>
                                    {/*<div className="serchRes">*/}
                                    {/*  <img*/}
                                    {/*    src={iconSerch}*/}
                                    {/*    alt="serch"*/}
                                    {/*    onClick={() => setShowSerch(!showSerch)}*/}
                                    {/*  />*/}
                                    {/*</div>*/}

                                    <div className="flag">
                                        <ul className="flags">
                                            <div className="flag-container">
                                                {languages?.map((language, index) =>
                                                    language.active ? (
                                                        <li
                                                            key={language.id}
                                                            className="global_flag"
                                                            onClick={() => setShowFlag(!showFlag)}
                                                        >
                                                            <img
                                                                onClick={switcher(language.name, index)}
                                                                onChange={onChangeLang}
                                                                src={language.img}
                                                                alt="flagUSA"
                                                                className="bigFlag"
                                                            />
                                                            <img
                                                                onClick={switcher(language.name, index)}
                                                                onChange={onChangeLang}
                                                                src={down}
                                                                alt="arrow down"
                                                                className="arrow-down"
                                                            />
                                                        </li>
                                                    ) : (
                                                        <div
                                                            style={{ display: showFlag ? "block" : "none" }}
                                                        >
                                                            {!language.active && (
                                                                <li key={language.id} className="global_flag">
                                                                    <img
                                                                        onClick={switcher(language.name, index)}
                                                                        src={language.img}
                                                                        alt="flagUSA"
                                                                        className="bigFlag"
                                                                    />
                                                                </li>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className={showSerch ? "closeSerch" : "noCloseSerch"}>
                                <div className={showSerch ? "inputSerch" : "inputRes"}>
                                    <Link to={`search/${q}`}></Link>
                                    <img
                                        src={iconSerch}
                                        alt="serch"
                                        className="iconSerch-res"
                                        onClick={openprofile}
                                    />
                                    <input
                                        type="text"
                                        placeholder={t("homePage.search")}
                                        value={q}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                    <img
                                        style={{ display: closeSerch ? "none" : "block" }}
                                        src={iconCloseSerch}
                                        alt="closeSerch"
                                        className="iconCloseSerch"
                                        onClick={() => setShowSerch(!showSerch)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="nav__forRes-down">
                            <div
                                className="burger"
                                onClick={() => setOpenBurger(!openBurger)}
                            >
                                <img
                                    className="burgerImg"
                                    src={burger}
                                    alt="burger"
                                    style={{ display: openBurger ? "block" : "none" }}
                                />
                                <img
                                    className="closeImg"
                                    src={close}
                                    alt="close"
                                    style={{ display: openBurger ? "none" : "block" }}
                                />
                            </div>

                            <div className="nav-inputt">
                                <Link to={`search/${q}`}></Link>
                                <img
                                    src={iconSerch}
                                    alt="serch"
                                    className="serch-icon"
                                    onClick={openprofile}
                                />
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <input
                                        className="myinputsearch"
                                        type="text"
                                        placeholder={t("homePage.search")}
                                        onKeyDown={handleKeyDown}
                                        value={q}
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                    {q && (
                                        <button
                                            className="clear-button"
                                            onClick={clearInput}
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "55%",
                                                transform: "translateY(-50%)",
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                            }}>
                                            <img src={iconCloseSerch} alt="clear" />
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="user-iconRes">
                                <Link to="favorit">
                                    <img src={like} alt="like" className="like" />{" "}
                                    <span className={todos.length > 0 ? "likespan" : "likenone"}>
                    {todos.length}
                  </span>
                                </Link>
                                <div className="headerchat">
                                    <img
                                        onClick={() => setOpenShop(!openShop)}
                                        src={shop}
                                        alt="shop"
                                        className="shop"
                                    />
                                    <span
                                        className={shopMenu?.length > 0 ? "likespan" : "likenone"}
                                    >
                    {shopMenu?.length}
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="menuRes">
                        <div className={openBurger ? "ResponsMenuNon" : "ResponsMenu"}>
                            <MenuRespons
                                openBurger={openBurger}
                                setOpenBurger={setOpenBurger}
                            />
                        </div>
                    </div>

                    <ul className={openBurger ? "global-menu" : "global-menuRes"}>
                        <li className="global-menu-block">
                            <div className="catalog__categoria">
                                <li className="global-menu__item catalog">
                                    {t("mainMenu.catalog")}
                                </li>
                                <img src={down} alt="down" className="catalogIcone" />
                                <img
                                    src={catalogdown}
                                    alt="catalogdown"
                                    className="catalogGreen"
                                />
                            </div>

                            <div>
                                <ul className="global-menu-block1 ">
                                    {posts &&
                                        posts.map((menu, index) => {
                                            const depthLevel = 0;
                                            return (
                                                <Menunew
                                                    items={menu}
                                                    key={index}
                                                    depthLevel={depthLevel}
                                                />
                                            );
                                        })}
                                </ul>
                            </div>
                        </li>
                        <img src={I} className="row" alt="abio" />

                        <Link className="menu__item" to={`Services/${i18n.language}`}>
                            <li className="global-menu__item "> {t("mainMenu.service")} </li>
                        </Link>
                        <img src={I} className="row" alt="down" />
                        <Link className="menu__item" to={`videoLesson/${i18n.language}`}>
                            {" "}
                            <li className="global-menu__item ">{t("mainMenu.video")}</li>
                        </Link>
                        <img src={I} className="row" alt="down" />
                        <Link className="menu__item" to={`giftCard/${i18n.language}`}>
                            {" "}
                            <li className="global-menu__item ">{t("mainMenu.gift")}</li>
                        </Link>
                        <img src={I} className="row" alt="down" />
                        <Link
                            className="menu__item"
                            to={`PaymentDelivery/${i18n.language}`}
                        >
                            {" "}
                            <li className="global-menu__item ">{t("mainMenu.payment")}</li>
                        </Link>
                    </ul>
                </nav>
                {/* <div className="row-line"></div> */}
                <div
                    className="shopingCard"
                    style={{ display: openShop ? "block" : "none" }}
                >
                    <BagPage
                        openShop={openShop}
                        setOpenShop={setOpenShop}
                        shoping={shoping}
                    />
                </div>
            </div>
        </>
    );
};

export { Menu };
