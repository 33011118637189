import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom"
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "../../../scss/slide.scss";
import "../../../scss/responsive/slide.scss"
import ReadmoreHeader from "./ReadmoreHeader";

import axios from "axios";
import {useTranslation} from "react-i18next";

// import required modules
import {Navigation, Pagination, Mousewheel, Keyboard, Autoplay} from "swiper";
import {useState, useEffect} from "react";

const instance = axios.create({
    withCredentials: true
})

const Slider = () => {
    const [slideCard, setslideCard] = useState([])

    const [lezu, setlezu] = useState('')
    const [isReadMore, setIsReadMore] = useState(true);

    let url = process.env.REACT_APP_BASE_URL
    const {t, i18n} = useTranslation()
    const toggleReadMore = () => {
        setIsReadMore(isRe => !isRe);
    };


    let getActiveCountry = JSON.parse(localStorage.getItem('lng'))
    useEffect(() => {
        getActiveCountry?.filter((item) => {
            if (item.active) {
                return setlezu(item.name)
            }
        })

    }, [JSON.parse(localStorage.getItem('lng'))])


    // const fetchPosts = async () => {
    //     let lang = i18n.language.slice(0, 2)
    //     await instance.get(`${url}/abio/public/get/headers?language=${lang}`)
    //         .then(res => {
    //             setslideCard(res.data)
    //         }).catch(er => console.log(er));
    // }

    // const fetchPosts = async () => {
    //     let lang = i18n.language.slice(0, 2);
    //     await instance.get(`${url}/abio/public/get/headers?language=${lang}`)
    //         .then(res => {
    //             let data = res.data;
    //             let reorderedData = [
    //                 data[2],
    //                 data[5],
    //                 data[4],
    //                 data[3],
    //                 data[1],
    //                 data[0]
    //             ];
    //             setslideCard(reorderedData);
    //         })
    //         .catch(er => console.log(er));
    // };
    const fetchPosts = async () => {
        let lang = i18n.language.slice(0, 2);
        await instance.get(`${url}/abio/public/get/headers?language=${lang}`)
            .then(res => {
                // Сортировка массива по значению alt
                const sortedData = res.data.sort((a, b) => a.alt - b.alt);
                setslideCard(sortedData);
            }).catch(er => console.log(er));
    };



    useEffect(() => {
        fetchPosts()
    }, [i18n.language]);

    return (
        <div className="homeSwiper-container">
            <Swiper

                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 3,
                    },
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 3,
                    },
                    744: {
                        slidesPerView: 2,
                        spaceBetween: 3,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 3,
                    },
                }}

                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                cssMode={true}
                navigation={true}
                pagination={{
                    clickable: true,
                  }}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}

                className="mySwiper HomeSlider"
                id="HomeSlide"
            >

                {slideCard.map((item) => (
                    <SwiperSlide key={item.id} className="slide_block">
                        <div className="slide_item">
                            <img src={`${url}/abio/public/header/picture?headerId=${item.id}`} alt="image" />
                            <div className="text-slide">

                                <ReadmoreHeader  key ={item.id}  item={item}/>
                                <p className="boldTxt-slide">{item?.description}</p>
                                <Link to={item.url}>
                                    <button className="btn__forSlide">{t("giftCard.buy")}</button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}




            </Swiper>

        </div>
    )
}

export {Slider}