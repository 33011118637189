import {useEffect, useState, useMemo, useRef} from "react"
// import data from "../Data.json"
import "../../scss/pagination.scss";
import ReactPaginate from "react-paginate";
import "../../scss/product.scss"
import "../../scss/filter.scss"
import {Link, useParams, useLocation} from "react-router-dom";
import filterImg from "../../images/filter.svg"
import sortImg from "../../images/sort.svg"
import down from "../../images/down.png"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Filter} from "./Filter"
import {Catalog} from "./Catalog"
import row from "../../images/row.png"
import {useTranslation} from "react-i18next";
import closeRow from "../../images/closeRow.svg"
import next from "../../images/next.png"
import prev from "../../images/prev.png"
import multi from "../../images/color/multe.svg"
import white from "../../images/color/white.svg"
import Black from "../../images/color/Black.svg"
import Blue from "../../images/color/Blue.svg"
import Red from "../../images/color/Red.svg"
import Green from "../../images/color/Green.svg"
import Yellow from "../../images/color/Yellow.svg"
import Brown from "../../images/color/Brown.svg"
import Gray from "../../images/color/Gray.svg"
import Beige from "../../images/color/Beige.svg"
import Purple from "../../images/color/Purple.svg"
import Silver from "../../images/color/Silver.svg"
import Gold from "../../images/color/Gold.svg"
import Pink from "../../images/color/Pink.svg"
import Orange from "../../images/color/Orange.svg"
import axios from "axios";
import {Input} from "@mui/material";

import {makeStyles} from '@material-ui/core/styles';
import {Cookies} from "react-cookie";


const instance = axios.create({
    withCredentials: true
})

const useStyles = makeStyles({
    root: {

        "& label.Mui-focused": {
            color: "#113E2E"
        },
        "& .MuiOutlinedInput-input": {
            color: "#113E2E"
        },


        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#113E2E"
            }
        }
    }
});

const Card = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);



    const [openFilter, setOpenFilter] = useState(false)
    const [isOpenColor, setIsOpenColor] = useState(true);
    const [isOpenRange, setIsOpenRange] = useState(true)
    const [isOpenSort, setIsOpenSort] = useState(false)
    const [clickedcolor, setclickedcolor] = useState('')

    const [sportList, setSportList] = useState([]);
    const [posts, setPost] = useState([])

    const [selectedCategory, setSelectedCategory] = useState('');
    const [color, setColor] = useState('')
    const [filterTags, setFilterTags] = useState('')
    const [first, setFirst] = useState('')


    const [pages, setpages] = useState(0)
    const [cartItems, setCartItems] = useState(() => {
        const ls = localStorage.getItem("bookmarks");
        if (ls) return JSON.parse(ls);
        else return [];
    });


    const [addHeader, setaddHeaders] = useState([])


    const classes = useStyles();

    let url = process.env.REACT_APP_BASE_URL;


    const {t, i18n} = useTranslation()
    let lang = i18n.language.slice(0, 2)

    const {category2} = useParams()
    const {category3} = useParams()
    const {category1} = useParams()
    const {catalogs} = useParams()



    const handleChartClick = (e) => {
        e.preventDefault();


    }
    const handleChartClickFav = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('favestay')

    }


    const handleChange = (event) => {
        setSelectedCategory(event.target.value);


    };
    const handleChangeMob = (event) => {
        setSelectedCategory(event.target.value);

        setIsOpenSort(!isOpenSort)

    };

    const handlefilter = (event) => {


        if (event.target.value === filterTags) {

            return setFilterTags('');


        } else {

            return setFilterTags(event.target.value);


        }


    };


    const handlePrice = (event) => {

        if (event.target.value === filterTags) {

            setFilterTags('')
            setFirst('')


        }


    };

    const handlecolorfilter = (event) => {

        if (event.target.value === color) {
            return setColor('')
        } else {
            return setColor(event.target.value);

        }


    };

    function handleClickcolor(ind) {

        const slider = color_content.id
        setclickedcolor(slider)

    }

    const ref = useRef('');


    function handleAnim(event) {
        // event.currentTarget.classList.remove('shopCart')
        event.currentTarget.classList.add('shopCart')


    }


    const fetchCatalogPosts = async (lng) => {
        let lang = lng.slice(0, 2)

        await instance.get(`${url}/abio/public/get/catalogs?`,
            {
                params: {
                    language: lang
                }
            }
        )
            .then(res => setPost(res.data))
            .catch(er => console.log(er))


    }



    useEffect(() => {
        fetchCatalogPosts(i18n.language)



    }, [i18n.language]);


    useEffect(() => {

        localStorage.setItem("bookmarks", JSON.stringify(cartItems));

    }, [cartItems]);
    useEffect(() => {
        setCartItems(JSON.parse(localStorage.getItem("bookmarks")) || [])

    }, [localStorage.getItem("bookmarks")]);


    const favadd = (product) => {

        const exist = cartItems.find((x) => x.productCode === product.productCode);
        if (exist) {
            setCartItems(
                cartItems.map((x) =>
                    x.productCode === product.productCode ? {...exist} : x
                )
            );
        } else {
            setCartItems([...cartItems, {...product}]);
        }


    }


    useEffect(() => {
        const isCanseled = axios.CancelToken.source();
        let lang = i18n.language.slice(0, 2)

        instance.get(`${url}/abio/public/filter`
            , {
                params: {

                    catalogId: catalogs,
                    sortingMethod: selectedCategory,
                    color: color,
                    minPrice: first,
                    maxPrice: filterTags,
                    page: currentPage - 1,
                    size: 12,
                    language: lang
                },

                isCanseled: isCanseled.token
            }
        ).then(response => {
                setpages(response.headers.page_size)
                setSportList(response.data)
            }
        ).catch(er => console.log(er));

        return () => {
            isCanseled.cancel()
        }

    }, [currentPage, category1, category2, catalogs, category3, i18n.language, color, filterTags, first, selectedCategory]);


    useEffect(() => {
        filterHandler()
    }, [i18n.language, pages, color, filterTags, first, selectedCategory ])

    const cookies = new Cookies();
    // const onAdd = async (prod) => {
    //     await instance.post(`${url}/abio/public/cart/addProduct`, {},
    //         {
    //             headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
    //             params: {
    //                 productCode: prod,
    //                 quantity: 1
    //             }
    //         }).then(res => setaddHeaders(res.data))
    //         .catch(er => console.log(er));
    // };

    const onAdd = async (prod) => {
        await instance.post(`${url}/abio/public/cart/addProduct`, {},
            {
                headers: { 'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S') },
                params: {
                    productCode: prod,
                    quantity: 1
                }
            }).then(res => {
            setaddHeaders(res.data);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'event': 'add to cart',
                'ecommerce': {
                    'items': res.data.cartProductDTOList.map((product, index) => ({
                        'item_name': product.name_en,
                        'item_id': product.productCode,
                        'price': product.price,
                        'item_list_name': 'cart',
                        'index': index + 1,
                        'quantity': product.quantity
                    }))
                }
            });
        }).catch(er => console.log(er));
    };

    const color_content = [
        {
            id: 1,
            color: 'B',
            name: 'B',
            title: t("Filter.black"),
            img: Black
        },
        {
            id: 2,
            color: 'BL',
            name: 'BL',
            title: t("Filter.blue"),
            img: Blue
        },
        {
            id: 3,
            color: 'color-red',
            name: 'R',
            title: t("Filter.red"),
            img: Red

        },
        {
            id: 6,
            name: "BR",
            title: t("Filter.brown"),

            // title2: t("Filter.brown1"),
            img: Brown
        },
        {
            id: 7,
            name: "G",
            title: t("Filter.gray"),
            img: Gray
        },
        {
            id: 13,
            name: 'O',
            title: t("Filter.orange"),

            img: Orange


        },

        {
            id: 5,
            name: "Y",
            title: t("Filter.yellow"),

            img: Yellow
        },


        {
            id: 8,

            name: 'I',
            title: t("Filter.Ivory"),
            img: Beige
        },


        {
            id: 10,

            name: 'P',
            title: t("Filter.pink"),

            img: Pink

        },
        {
            id: 11,
            name: 'GL',
            title: t("Filter.gold"),

            img: Gold


        },
        {
            id: 12,
            name: 'S',
            title: t("Filter.silver"),

            img: Silver


        },

        {
            id: 4,
            color: 'color-green',
            name: 'GR',
            title: t("Filter.green"),
            img: Green


        },
        {
            id: 9,
            color: 'color-purple',
            name: 'V',
            title: t("Filter.Purple"),
            // title2:t("Filter.Purple2"),

            img: Purple


        },
        {
            id: 14,

            name: 'C',
            title: t("Filter.multi"),
            title2: t("Filter.multi2"),
            img: multi


        },

        {
            id: 0,
            color: 'color-white',
            name: "W",
            title: t("Filter.white"),
            title2: t("Filter.white2"),
            img: white

        }


    ]


    const filterHandler = () => {


        if (filterTags === '5000') {
            setFirst('0')
            return sportList.filter(el => (el?.discount === 0 ? el?.price : el?.discountPrice) <= 5000)
        } else if (filterTags === '20000') {
            setFirst('5000')

            return sportList.filter(el => (el?.discount === 0 ? el?.price : el?.discountPrice) >= 5000 && (el?.discount === 0 ? el?.price : el?.discountPrice) <= 20000)
        } else if (filterTags === '50000') {
            setFirst('20000')
            return sportList.filter(el => (el?.discount === 0 ? el?.price : el?.discountPrice) >= 20000 && (el?.discount === 0 ? el?.price : el?.discountPrice) <= 50000)
        } else if (filterTags === '150000') {
            setFirst('50000')
            return sportList.filter(el => (el?.discount === 0 ? el?.price : el?.discountPrice) >= 50000 && (el?.discount === 0 ? el?.price : el?.discountPrice) <= 150000)
        } else if (filterTags === '50000000') {
            setFirst('150000')

            return sportList.filter(el => (el?.discount === 0 ? el?.price : el?.discountPrice) >= 150000)
        }


        return sportList;


    }


    var filteredList = useMemo(filterHandler, [filterTags, selectedCategory, color, sportList]);

    let hedtext = posts?.map((x) => x?.children.find(el => el.code == catalogs)?.full_name)
    let newhead = posts?.map(el => el?.children)


    let snew = newhead?.flat().map((x) => x?.children.find(el => el.code == catalogs)?.full_name)
    let hedtexxt = ''
    if (snew) {
        hedtexxt = snew?.filter(el => el).join('')
    }


    const currentPosts = [...sportList].map((item) => {

        return (


            <div key={item?.productCode}>
                   <Link className="product__link"
                      to={`/catalog/${item?.catalogs?.slice(1, item?.catalogs?.indexOf('/')).replace(/\s+/g, '-').toLowerCase()}/${item?.productCode}/${i18n.language} `}>
                    <div className="product my_product_card">
                        {item?.labelId !== null ? <img className="product_label" alt='abio'
                                                       src={`${url}/abio/public/getLabel?labelId=${item?.labelId}`}/> : null}

                        <svg onClick={handleChartClickFav}
                             onMouseDown={() => favadd(item)} className="favoritSvg"
                             width="27" height="27" viewBox="0 0 27 27" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="27" height="27" rx="10" fill="#FDFDFF"/>
                            <path
                                d="M13.0772 21.0635L6.34668 14.333C4.5542 12.5405 4.50276 9.72478 6.1484 8.07914L6.14856 8.07898C7.79312 6.43292 10.6087 6.48473 12.4022 8.27742C12.4023 8.27745 12.4023 8.27747 12.4023 8.2775L12.7245 8.59973L13.0781 8.95329L13.4316 8.59973L13.7539 8.2775C13.7539 8.27747 13.7539 8.27745 13.754 8.27742C15.5223 6.50988 18.2843 6.4347 19.9378 8.00996L20.0069 8.07906C21.6517 9.72469 21.6003 12.5404 19.8071 14.3329L19.807 14.333L13.0772 21.0635Z"
                                stroke="#0E0E0E"/>
                        </svg>

                        <img className="product-img" alt={item?.pictureIds[0]?.[`alt_${i18n.language}`]}
                             src={`${url}/abio/public/files?productCode=${item?.productCode}&fileName=${item?.pictureIds[0]?.pictureName}`}/>
                        <div className="prodquantity">
                            <p className="rec_name">{item?.name}</p>

                        </div>

                        <div className="rec-colors">

                            {item?.colorCodes?.length > 0 ? item?.colorCodes.map((el, ind) => {
                                    return (

                                        <div className={el} key={ind}></div>


                                    )
                                }
                            ) : null}

                        </div>


                        <div className="rec-price">
                            <div className="price">

                                <p className="new-price"> {item?.discount === 0 ? item?.price.toLocaleString() : item?.discountPrice.toLocaleString()} AMD</p>
                                <p className="old-price">{item?.discount > 0 ? item?.price.toLocaleString() : null} {item?.discount > 0 ? ' AMD' : null} </p>
                            </div>

                            <div ref={ref} onMouseDown={handleAnim}

                            >
                                <svg onClick={handleChartClick} onMouseDown={() => onAdd(item?.productCode)}
                                     className='shopIcon' width="27" height="27" viewBox="0 0 27 27" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    {/* <rect width="17" height="17" rx="10" fill="#FDFDFF"/> */}
                                    <path transform="translate(0 0)"
                                          d="M7.875 24.75C7.25625 24.75 6.72675 24.5299 6.2865 24.0896C5.8455 23.6486 5.625 23.1187 5.625 22.5C5.625 21.8813 5.8455 21.3514 6.2865 20.9104C6.72675 20.4701 7.25625 20.25 7.875 20.25C8.49375 20.25 9.02325 20.4701 9.4635 20.9104C9.9045 21.3514 10.125 21.8813 10.125 22.5C10.125 23.1187 9.9045 23.6486 9.4635 24.0896C9.02325 24.5299 8.49375 24.75 7.875 24.75ZM19.125 24.75C18.5063 24.75 17.9767 24.5299 17.5365 24.0896C17.0955 23.6486 16.875 23.1187 16.875 22.5C16.875 21.8813 17.0955 21.3514 17.5365 20.9104C17.9767 20.4701 18.5063 20.25 19.125 20.25C19.7437 20.25 20.2736 20.4701 20.7146 20.9104C21.1549 21.3514 21.375 21.8813 21.375 22.5C21.375 23.1187 21.1549 23.6486 20.7146 24.0896C20.2736 24.5299 19.7437 24.75 19.125 24.75ZM6.91875 6.75L9.61875 12.375H17.4937L20.5875 6.75H6.91875ZM7.875 19.125C7.03125 19.125 6.39375 18.7545 5.9625 18.0135C5.53125 17.2732 5.5125 16.5375 5.90625 15.8063L7.425 13.05L3.375 4.5H2.22188C1.90313 4.5 1.64062 4.392 1.43437 4.176C1.22812 3.96075 1.125 3.69375 1.125 3.375C1.125 3.05625 1.233 2.78887 1.449 2.57287C1.66425 2.35762 1.93125 2.25 2.25 2.25H4.07812C4.28437 2.25 4.48125 2.30625 4.66875 2.41875C4.85625 2.53125 4.99688 2.69063 5.09063 2.89688L5.85 4.5H22.4438C22.95 4.5 23.2969 4.6875 23.4844 5.0625C23.6719 5.4375 23.6625 5.83125 23.4563 6.24375L19.4625 13.4437C19.2562 13.8187 18.9844 14.1094 18.6469 14.3156C18.3094 14.5219 17.925 14.625 17.4937 14.625H9.1125L7.875 16.875H20.2781C20.5969 16.875 20.8594 16.9826 21.0656 17.1979C21.2719 17.4139 21.375 17.6812 21.375 18C21.375 18.3188 21.267 18.5858 21.051 18.801C20.8358 19.017 20.5688 19.125 20.25 19.125H7.875ZM9.61875 12.375H17.4937H9.61875Z"
                                          fill="#0E0E0E"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

        );
    });

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        window.scrollTo({ top: 50, behavior: 'smooth' });
        setCurrentPage(newOffset);

        const newUrlSearchParams = new URLSearchParams(window.location.search);
        newUrlSearchParams.set('page', newOffset);
        const newUrl = `${window.location.pathname}?${newUrlSearchParams.toString()}`;
        window.history.pushState({}, '', newUrl);
    };

    useEffect(() =>{
       if(page && Number(page) !== currentPage){
          setCurrentPage(Number(page))
       }
    },[page]);

    return (
        <>
            <div className="container">

                <div className="product-top">
                    <div className="prosuct__text">

                        <p> {t("mainMenu.catalog1")}/{hedtext}


                            <Link className="prodLink" to={`/menucatalog/${category1}/${category2}/${catalogs.slice(0,2)}/${i18n.language}`}>
                            <span>{hedtexxt ? hedtexxt?.slice(hedtexxt.indexOf('/') + 1, hedtexxt.lastIndexOf('/')) : ''}</span>
                            </Link>


                            <span
                                className="green__text">{hedtexxt ? '/ ' + hedtexxt?.slice(hedtexxt.lastIndexOf('/') + 1) : ''}</span>
                        </p></div>
                    <div className="respons__sord-filter">
                        <div className="respons__sord-filter-item" style={{borderBottom: openFilter ? "2px solid #0e0e0e": "none"} }
                             onClick={() => setOpenFilter(!openFilter)}>
                            <img src={filterImg} alt="filterImg"/>
                            <p  onClick={() => setIsOpenSort(false)}>{t("Filter.filter")}</p>

                        </div>
                        <div className="respons__sord-filter-item" style={{borderBottom: isOpenSort ? "2px solid #0e0e0e": "none"} }
                             onClick={() => setIsOpenSort(!isOpenSort)}>
                            <img src={sortImg} alt="sortImg"/>
                            <p  onClick={() => setOpenFilter(false)}>{t("Filter.sort")}</p>
                        </div>

                    </div>
                    <form>
                        <Box className='basicSelect' sx={{minWidth: 250}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" sx={{minWidth: 250}}
                                            className={classes.root}>{t("Filter.sort")}</InputLabel>

                                <Select

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCategory}
                                    label={t("Filter.sort")}
                                    onChange={handleChange}


                                >

                                    {/* <MenuItem className='sort_item' name="20"
                                              value='BEST_SELLER'>{t("Filter.bestsellers")}</MenuItem> */}
                                    <MenuItem className='sort_item' name="30"
                                              value='HIGH_TO_LOW'>{t("Filter.lowprice")}</MenuItem>
                                    <MenuItem className='sort_item' name="40"
                                              value='LOW_TO_HIGH'>{t("Filter.highprice")}</MenuItem>
                                    <MenuItem className='sort_item' name="50"
                                              value='NEW_ARRIVALS'>{t("Filter.newarrivals")}</MenuItem>
                                    <MenuItem className='sort_item' name="60"
                                              value='A_Z'>{t("Filter.nameA-Z")}</MenuItem>
                                    <MenuItem className='sort_item' name="70"
                                              value='Z_A'>{t("Filter.nameZ-A")}</MenuItem>
                                    {/* <MenuItem className='sort_item' name="80"
                                              value="SALE">{t("Filter.sale")}</MenuItem>
                                    <MenuItem className='sort_item' name="90"
                                              value='TODAY_OFFER'>{t("Filter.offers")}</MenuItem> */}
                                    <MenuItem className='sort_item' name="100" value='NONE'>{t("Filter.all")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </form>
                </div>
            </div>

            <div className="product__card">

                <div className="product-down">
                    <div className="container card__container">

                        <div className="product-filter">
                            <Catalog/>
                            <div>

                                <p className="filter-text">{t("Filter.filter")}</p>
                                <img src={row} alt="row" className="row-img"/>
                            </div>
                            <div className="filter-colors">
                                <div onClick={() => setIsOpenColor(!isOpenColor)} className="filter-color__block">
                                    <p>{t("Filter.filterColor")}</p>
                                    <img src={down} alt=""/>
                                </div>
                                <div className={isOpenColor ? "filter-color__item" : "filter__cardNew"}>
                                    <div className="color-area">

                                        <div className="color-area__item">
                                            {color_content.map((el, ind) => {
                                                return (

                                                    <div className="color_content" key={el.id}>
                                                        <button className="btn__color" value={el.name}
                                                                onClick={handlecolorfilter}>
                                                            <img src={el.img} alt="color__img"
                                                                 onClick={() => handleClickcolor(ind)}
                                                                 className={color === el.name ? ` clickedcolor ` : 'clickedcolor notClicked'}/>

                                                        </button>
                                                        <p>{el.title}</p><br/>
                                                        <p>{el.title2}</p>


                                                    </div>

                                                )
                                            })
                                            }


                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="filter-range">
                                <div onClick={() => setIsOpenRange(!isOpenRange)} className="filter-range__block">
                                    <p>{t("Filter.price")}</p>
                                    <img src={down} alt="down" className="down-img"/>
                                </div>
                                <div className={isOpenRange ? "filter-range__item" : "newRange"}>
                                    <div>
                                        <input className="input-chekt"

                                               id="range5"
                                               type="radio"
                                               checked={filterTags === '5000'}
                                               onChange={handlefilter}
                                            // name = '10'
                                               onClick={handlePrice}

                                               value='5000'/>

                                        <label htmlFor="range5"> {t("Filter.under")} 5000 AMD </label>
                                    </div>
                                    <div>
                                        <input className="input-chekt"
                                               name='20'
                                               checked={filterTags === '20000'}
                                               type="radio"
                                               id="range20"
                                               onChange={handlefilter}
                                               onClick={handlePrice}
                                               value='20000'/>
                                        <label
                                            htmlFor="range20"> 5000-20.000 AMD</label>
                                    </div>
                                    <div>
                                        <input className="input-chekt"
                                               name='30'
                                               checked={filterTags === '50000'}
                                               type="radio"
                                               id="range50"
                                               onChange={handlefilter}
                                               onClick={handlePrice}
                                               value="50000"/>
                                        <label
                                            htmlFor="range50"> 20.000-50.000 AMD</label>
                                    </div>
                                    <div>
                                        <input className="input-chekt"
                                               name='40'
                                               type="radio"
                                               onChange={handlefilter}
                                               onClick={handlePrice}
                                               checked={filterTags === '150000'}
                                               value="150000"
                                               id="range150"/>
                                        <label
                                            htmlFor="range150"> 50.000-150.000 AMD</label>
                                    </div>
                                    <div>
                                        <input className="input-chekt"
                                               onChange={handlefilter}
                                               checked={filterTags === '50000000'}
                                               onClick={handlePrice}
                                               name='50'
                                               type="radio"
                                               value="50000000"
                                               id="rangeplus"/>
                                        <label
                                            htmlFor="rangeplus"> {t("Filter.above")} 150 000 AMD</label>
                                    </div>




                                </div>

                            </div>


                        </div>


                        <div style={{display: isOpenSort  ? "block" : "none"}}  className="responsive__sort">
                            <img src={closeRow} alt="closeRow" onClick={() => setIsOpenSort(!isOpenSort)}/>
                            <div className="sortByRes">

                                {/* <label htmlFor="name2" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name2" value='BEST_SELLER' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.bestsellers")} </label> */}
                                <label htmlFor="name3" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name3" value='HIGH_TO_LOW' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.lowprice")} </label>
                                <label htmlFor="name4" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name4" value='LOW_TO_HIGH' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.highprice")} </label>
                                <label htmlFor="name5" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name5" value='NEW_ARRIVALS' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.newarrivals")} </label>
                                <label htmlFor="name6" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name6" value='A_Z' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.nameA-Z")}</label>
                                <label htmlFor="name7" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name7" value='Z_A' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.nameZ-A")} </label>
                                {/* <label htmlFor="name8" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name8" value='SALE' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.sale")} </label> */}
                                {/* <label htmlFor="name9" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name9" value='TODAY_OFFER' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.offers")} </label> */}
                                <label htmlFor="name10" onClick={() => setIsOpenSort(!isOpenSort)}
                                       className="radioLabel">
                                    <Input type="radio" id="name10" value='NONE' className="radioHide"
                                           onChange={handleChange}/>{t("Filter.all")} </label>

                            </div>
                            <div>

                            </div>
                        </div>

                        <div style={{display: openFilter ? "block" : "none"}}   className="responsive__filter">
                            <img src={closeRow} alt="closeRow" onClick={() => setOpenFilter(!openFilter)}/>
                            <div>
                                <Catalog/>
                                <div>

                                    <p className="filter-text">{t("Filter.filter")}</p>
                                    <img src={row} alt="row" className="row-img"/>
                                </div>

                                <div onClick={() => setIsOpenColor(!isOpenColor)} className="filter-colors">
                                    <div className="filter-color__block">
                                        <p>{t("Filter.filterColor")}</p>
                                        <img src={down} alt=""/>
                                    </div>
                                    <div className={isOpenColor ? "filter-color__item" : "filter__cardNew"}>
                                        <div className="color-area">

                                            <div className="color-area__item">
                                                {color_content.map((el, ind) => {
                                                    return (

                                                        <div className="color_content" key={el.id}>
                                                            <button className="btn__color" value={el.name}
                                                                    onClick={handlecolorfilter}>
                                                                <img src={el.img} alt="color__img"
                                                                    //  className={el.name}
                                                                     className={color === el.name ? ` clickedcolor ` : 'clickedcolor notClicked'}
                                                                />

                                                            </button>
                                                            <p>{el?.title}</p><br/>

                                                            <p>{el?.title2}</p>

                                                        </div>

                                                    )
                                                })
                                                }
                                            </div>

                                        </div>


                                    </div>

                                </div>
                                <div className="filter-range">
                                    <div onClick={() => setIsOpenRange(!isOpenRange)} className="filter-range__block">
                                        <p>{t("Filter.price")}</p>
                                        <img src={down} alt="down" className="down-img"/>
                                    </div>
                                    <div className="rangeRespons" style={{display: isOpenRange ? "none" : "block"}}>
                                        <div>
                                            <input className="input-chekt"
                                                   checked={filterTags === '5000'}
                                                   id="range5"
                                                   type="radio"
                                                   onClick={handlePrice}
                                                   onChange={handlefilter}
                                                   value="5000"/>
                                            <label htmlFor="range5"> {t("Filter.under")} 5000 AMD</label>
                                        </div>
                                        <div>
                                            <input className="input-chekt"
                                                   checked={filterTags === '20000'}
                                                   type="radio"
                                                   id="range20"
                                                   onChange={handlefilter}
                                                   onClick={handlePrice}
                                                   value="20000"/>
                                            <label
                                                htmlFor="range20"> 5000-20.000 AMD</label>
                                        </div>
                                        <div>
                                            <input className="input-chekt"
                                                   checked={filterTags === '50000'}
                                                   type="radio"
                                                   id="range50"
                                                   onChange={handlefilter}
                                                   onClick={handlePrice}
                                                   value="50000"/>
                                            <label
                                                htmlFor="range50"> 20.000-50.000 AMD</label>
                                        </div>
                                        <div>
                                            <input className="input-chekt"
                                                   checked={filterTags === '150000'}
                                                   type="radio"
                                                   onChange={handlefilter}
                                                   onClick={handlePrice}
                                                   value="150000" id="range150"/>
                                            <label
                                                htmlFor="range150"> 50.000-150.000 AMD</label>
                                        </div>
                                        <div>
                                            <input className="input-chekt"
                                                   onChange={handlefilter}
                                                   onClick={handlePrice}
                                                   checked={filterTags === '50000000'}
                                                   type="radio" value="5000000" id="rangeplus"/>
                                            <label
                                                htmlFor="rangeplus">{t("Filter.above")} 150.000 AMD </label>
                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="prod">
                            <div className="product-card">
                                {sportList.length === 0 || sportList === null || filteredList.length === 0 ? (
                                    <div className="noProduct">

                                        <p className="noText prodText">{t("Home.noData")}</p>
                                        <Link to={`/${i18n.language}`} className="back_home">{t("Home.back")}</Link>
                                    </div>
                                ) : (currentPosts)


                                }


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<img src={next}/>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        pageRange={5}
                        activePage={currentPage}
                        forcePage={currentPage-1}
                        pageCount={Math.ceil(pages)}
                        previousLabel={<img src={prev}/>}
                        renderOnZeroPageCount={() => null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                    />
                </div>
            </div>


        </>
    )
}
export {Card}