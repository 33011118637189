import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import '../../scss/tabs.scss';
import axios from "axios";
import { Cookies } from "react-cookie";

const AmeriaResult = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const transactionId = queryParams.get('transactionId');
    const paymentId = queryParams.get('paymentId');
    const status = queryParams.get('status');
    const errorMessage = queryParams.get('errorMessage') ?? '';
    console.log('????????', transactionId);


    let integerPart = null;
    if (transactionId) {
        const regex = /-(\d+)$/;
        const match = transactionId.match(regex);
        integerPart = match ? match[1] : null;
        console.log(integerPart);

    }

    console.log(integerPart);

    let url = process.env.REACT_APP_BASE_URL;

    const instance = axios.create({
        withCredentials: true
    });

    console.log('transactionId:', transactionId, 'paymentId:', paymentId, 'status:', status, 'errorMessage:', errorMessage);

    const [order, setOrder] = useState('');
    const [email, setEmail] = useState('');
    const { t, i18n } = useTranslation();
    const cookies = new Cookies();

    const getOrderInfo = async () => {
        try {
            const response = await instance.get(`${url}/abio/public/cart/resetOrder`, {
                headers: {
                    'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                    'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
                },
                params: {
                    orderId: integerPart,
                    paymentID: paymentId,
                    transactionId: transactionId
                }
            });
            setOrder(response.data.id);
            setEmail(response.data.email);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }


    useEffect(() => {
            getOrderInfo();
    }, []);

    return (
        <div className={status === 'success' ? 'success' : 'fail'}>
            {status === 'success' ? (
                order ? (
                    <>
                        <div className='modalCont'>
                            <h2><input type="checkbox" className="modalcheck" checked readOnly/>{t("Tabs.thanks")}</h2>
                        </div>
                        <div className="modalText">
                            {t("Tabs.thanksdetail1")} <span>{order}</span>
                            {t("Tabs.thanksdetail2")} {email} {t("Tabs.thanksdetail3")}
                        </div>
                        <Link to={`/${i18n.language}`}>
                            <button className="continuShop">{t("shop.continue")}</button>
                        </Link>
                    </>
                ) : (
                    <>
                        <div className='modalCont'>
                            <h2 className='errortext'>{t("Tabs.thankserror")}</h2>
                        </div>
                        <Link to={`/${i18n.language}`}>
                            <button className="continuShop">{t("shop.continue")}</button>
                        </Link>
                    </>
                )
            ) : (
                <>
                    <div className="errortext">
                        {t("Tabs.fail")} {order} {t("Tabs.fail1")}
                    </div>
                    <div className="errortext">
                        {t("Tabs.faildetail2")} {email} {t("Tabs.faildetail3")}
                    </div>
                    {errorMessage && (
                        <div className="errortext">
                            {t("Tabs.errorMessage")}: {errorMessage}
                        </div>
                    )}
                    <Link to={`/${i18n.language}`}>
                        <button className="continueShop">
                            {t("shop.continue")}
                        </button>
                    </Link>
                </>
            )}
        </div>
    );
}

export { AmeriaResult };