import { Link } from "react-router-dom"
import "../../../scss/menu.scss"
import "../../../scss/responsive/menu.scss"
import { useState, useEffect, useRef } from "react";

import down from "../../../images/drop.svg"
import burger from "../../../images/burger.png"
import close from "../../../images/close.png"
import logo from "../../../images/logoabio.png"
import iconCloseSerch from "../../../images/closeSerch.png"
import iconePhone from "../../../images/iconePhone.png"
import catalogdown from "../../../images/catalogdown.svg"
import leftDown from "../../../images/leftDown.svg"
import I from "../../../images/i.png"
import iconSerch from "../../../images/iconSerch.png"
import {shopData} from "../../../data/shopData"
import { useTranslation } from "react-i18next";

import Dropdown from "./Dropdown";

const Menunew = ({ items, depthLevel }) =>{

    const [dropdown, setDropdown] = useState(false);

    let ref = useRef();

    const {t, i18n} = useTranslation()

    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("click", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("click", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);

    const onMouseEnter = () => {
      setDropdown(true);
    };

    const onMouseLeave = () => {
       setDropdown(false);
    };

    return (
      <li
      className="item  block1"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}

      >
        {items?.children?.length > 0 ? (
          <>
          {items?.url ?
           <Link
          className="item-name  activeItem1" to={`menucatalog/${items.full_name.replace(/\s+/g, '-').toLowerCase()}/${items.code}/${i18n.language}`}
          >

            {items.name}

            <img className="leftDown_img" src={leftDown} alt="image"  onClick={() => setDropdown((prev) => !prev)} />

            </Link>
             : <p  className="item-name  activeItem1">   {items.name}

           <img className="leftDown_img" src={leftDown} alt="image"  onClick={() => setDropdown((prev) => !prev)} /></p>}
            {" "}

            <Dropdown
              depthLevel={depthLevel}
              submenus={items.children}
              dropdown={dropdown}
            />
          </>
        ) : (
        <>

          <Link
          className="item-name activeItem " to={`menucatalog/${items.full_name.replace(/\s+/g, '-').toLowerCase()}/${items.code}/${i18n.language}`} >
            {items.name} </Link>


            </>

        )}{" "}
      </li>
    );



}
export default Menunew