import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";

import "../../scss/video.scss";
import "../../scss/responsive/video.scss";
function Readmore({ item }) {
    const { t, i18n } = useTranslation();

    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    return (
        <div key={item.id} className="video__area">
            <div className="video__video">
                <iframe
                    width="430px"
                    height="320px"
                    src={item?.url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
            <div className="video__info">
                <p className="video__name">{item?.title}</p>

                {item?.description.length < 350 ? (
                    <p className="video__text">{item?.description}</p>
                ) : (
                    <>
                        <p className="video__text">
                            {item?.description?.slice(0, 350)}
                            <span>{isExpanded ? "" : "..."}</span>
                            <span {...getCollapseProps()}>
                {item?.description?.slice(350)}
              </span>
                        </p>
                        <p
                            className="readMoreButton"
                            {...getToggleProps({
                                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                            })}
                        >
                            {isExpanded ? t("Home.showLess") : t("Home.readMore")}
                        </p>
                    </>
                )}


                <div className="video__data">
                    <p>{item.date}</p>
                </div>
            </div>
        </div>
    );
}

export default Readmore;
