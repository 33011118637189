import row from "../../../images/row.png"
import down from "../../../images/down.png"
import "../../../scss/menuRes.scss"
import "../../../scss/responsive/home.scss"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import dropdownWhite from "../../../images/downWhite.svg"
import { Link } from "react-router-dom";
import topWhiteClose from "../../../images/topWhite.svg"
import topBlack from "../../../images/topBlack.svg"
import topWhite from "../../../images/whiteDown.svg"
import MenuCatalog  from "./MenuCatalog";

import axios from "axios";

const instance = axios.create({
    withCredentials: true
  })


const MenuRespons=({openBurger, setOpenBurger})=>{
    const {t}=useTranslation()
  
  

    const [isOpenCatalog,setisOpenCatalog]=useState(true)
   


    let url = process.env.REACT_APP_BASE_URL;
    const [posts, setPost] = useState([])
    const {i18n } = useTranslation();
  const fetchPosts = async (lng) => {
    let lang = lng.slice(0, 2)

    await instance.get(`${url}/abio/public/get/catalogs?`,
        {
            params: {
                language: lang
            }
        }
        )
        .then(res => setPost(res.data))
        .catch(er => console.log(er))



}

useEffect(() => {
    fetchPosts(i18n.language)

}, [i18n.language]);




        return(
                <div style={{display:openBurger?"none":"block"}}>
                <div>
                <div className="catalogRes" onClick={()=>setisOpenCatalog(!isOpenCatalog)}>
                <p className="catalog-nameRes"> {t("mainMenu.catalog1")}</p>
                <img src={down} alt="down" className="downRes" />
                <img src={dropdownWhite} alt="dropdownWhite" className="dropdownWhiteRes" />
                </div>
                <img src={row} className="rowres" alt="image" />
                </div>
    
        <div style={{display:isOpenCatalog?"none":"block"}}>
       

<ul className="nocategory">
        {posts && posts.map( (menu,index)=>{

const depthLevel = 0;
return < MenuCatalog items={menu} key={index} depthLevel={depthLevel} handleActive={() => setOpenBurger(!openBurger)} />;
})}
</ul>


        <img src={row} className="rowres" alt="image" />

        </div>    

    
                <div className="catalogRes" onClick={()=>setOpenBurger(!openBurger)}    >
                <Link  className="link__category" to={`Services/${i18n.language}`}>
                    <p className="catalog-nameRes"> {t("mainMenu.service")}</p> </Link>
                </div>
                <img src={row} className="rowres" alt="abio" />
    
                <div className="catalogRes" onClick={()=>setOpenBurger(!openBurger)}  >
                <Link  className="link__category" to={`videoLesson/${i18n.language}`}>
                    <p className="catalog-nameRes"> {t("mainMenu.video")}</p> </Link>
                </div>
                <img src={row} className="rowres" alt="abio" />
    
                <div className="catalogRes" onClick={()=>setOpenBurger(!openBurger)} >
                <Link  className="link__category" to={`giftCard/${i18n.language}`}>
                    <p className="catalog-nameRes"> {t("mainMenu.gift")}</p> </Link>
                </div>
                <img src={row} className="rowres" alt="abio" />
    
                <div className="catalogRes" onClick={()=>setOpenBurger(!openBurger)} >
                <Link  className="link__category" to={`PaymentDelivery/${i18n.language}`}>
                    <p className="catalog-nameRes"> {t("mainMenu.payment")}</p> </Link>
                </div>
    
                </div>

            
        )
    
}
export {MenuRespons}