import {useState} from "react";
import "../../../scss/product.scss"

import "../../../scss/favorit.scss"
import "../../../scss/responsive/favorit.scss"

import {favoritData} from "../../../data/favoritData"
import shop from "../../../images/shop.svg"
import {useEffect} from "react"
import {useLocation, useParams} from "react-router-dom"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Cookies} from "react-cookie";


const instance = axios.create({
    withCredentials: true
})

const Favorit = () => {
    const {t, i18n} = useTranslation()
    let url = process.env.REACT_APP_BASE_URL;

    const {category2} = useParams()
    const {category3} = useParams()
    const {category1} = useParams()
    // const [favoritCard, setFavoritCard] = useState(favoritData);
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const cookies = new Cookies();


    const onAdd = async (prod) => {
        await instance.post(`${url}/abio/public/cart/addProduct`, {},
            {
                headers: { 'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S') },
                params: {
                    productCode: prod,
                    quantity: 1
                }
            }).then(response => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer .push ({ ecommerce: null });
            window.dataLayer.push({
                'event': 'add to cart',
                'ecommerce': {
                    'items': response.data.cartProductDTOList.map((product, index) => ({
                        'item_name': product.name_en,
                        'item_id': product.productCode,
                        'price': product.price,
                        'item_list_name': 'cart',
                        'index': index + 1,
                        'quantity': product.quantity
                    }))
                }
            });
        })
            .catch(er => console.log(er));
    };



    const handleChartClick = (e) => {
        e.preventDefault();
    }

    const [bookmarks, setBookmarks] = useState(() => {
        const ls = localStorage.getItem("bookmarks");
        if (ls) return JSON.parse(ls);
        else return [];
    });


    const onRemove = (product) => {


        const filterd = bookmarks.filter(el => {
            return el.productCode !== product
        })
        setBookmarks(filterd)
    };

    useEffect(() => {

        localStorage.setItem("bookmarks", JSON.stringify(bookmarks))
    }, [bookmarks]);


    return (
        <>

            <div className="container">

                <div className="favorit__top">
                    <p>{t("Home.home")} / </p>  <span className="favorit__top-green">{t("Home.wishlist")}</span>
                </div>
            </div>
            <div className="favorite">
                <div className="container">

                    <div className="favorit__container">
                        {bookmarks.length == 0 ?
                            (
                                <div className="no__favorit">
                                    <p className="noText">{t("Home.noFavorit")}</p>
                                    <Link to={`/${i18n.language}`} className="back_home">{t("Home.shoping")}</Link>
                                </div>
                            ) :
                            (
                                bookmarks?.map((item) =>

                                    (
                                        <div key={item.productCode}>

                                            <div className="product my_product_card">
                                                {item?.labelId !== null ? <img className="product_label"
                                                                               src={`${url}/abio/public/getLabel?labelId=${item?.labelId}`}/> : null}

                                                <div className="favoritImg" onClick={() => onRemove(item.productCode)}>
                                                    <svg className="favoritSvg" width="27" height="27"
                                                         viewBox="0 0 27 27" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="27" height="27" rx="10" fill="#FDFDFF"/>
                                                        <path
                                                            d="M13.0772 21.0635L6.34668 14.333C4.5542 12.5405 4.50276 9.72478 6.1484 8.07914L6.14856 8.07898C7.79312 6.43292 10.6087 6.48473 12.4022 8.27742C12.4023 8.27745 12.4023 8.27747 12.4023 8.2775L12.7245 8.59973L13.0781 8.95329L13.4316 8.59973L13.7539 8.2775C13.7539 8.27747 13.7539 8.27745 13.754 8.27742C15.5223 6.50988 18.2843 6.4347 19.9378 8.00996L20.0069 8.07906C21.6517 9.72469 21.6003 12.5404 19.8071 14.3329L19.807 14.333L13.0772 21.0635Z"
                                                            stroke="#0E0E0E"/>
                                                    </svg>
                                                </div>

                                                {/* <Link className="product__link" to={`/card/${item?.productCode}/${item?.category1[0]}/${item?.category2[0]}/${item?.category3[0] ? item.category3[0] : ''}`}> */}
                                                <Link className="product__link"
                                                      to={`/catalog/${item?.catalogs?.slice(1, item?.catalogs?.indexOf('/')).replace(/\s+/g, '-').toLowerCase()}/${item?.productCode}/${i18n.language}`}>
                                                    <img className="product-img" alt={item?.pictureIds?.[0]?.[`alt_${i18n.language}`]}
                                                         src={`${url}/abio/public/files?productCode=${item?.productCode}&fileName=${item?.pictureIds?.[0]?.pictureName}`}/>

                                                    <div className="prodquantity">
                                                        <p className="rec_name">{item?.name}</p>
                                                    </div>
                                                    <div className="rec-colors">
                                                        {item?.colorCodes?.length > 0 ? item?.colorCodes.map((el, ind) => {
                                                                return (
                                                                    <>
                                                                        <div className={el} key={ind}></div>

                                                                    </>
                                                                )
                                                            }
                                                        ) : null}
                                                    </div>

                                                    <div className="rec-price">
                                                        <div className="price">
                                                            <p className="new-price"> {item?.discount === 0 ? item?.price.toLocaleString() : item?.discountPrice.toLocaleString()} AMD</p>

                                                            <p className="old-price">{item?.discount > 0 ? item?.price.toLocaleString() : null} {item?.discount > 0 ? 'AMD' : null} </p>
                                                        </div>


                                                        <svg className="shopIcon"
                                                             onMouseDown={() => onAdd(item.productCode)}
                                                             onClick={handleChartClick} width="27" height="28"
                                                             viewBox="0 0 27 28" fill="black"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M8.33334 27.3332C7.60001 27.3332 6.97245 27.0723 6.45068 26.5505C5.92801 26.0278 5.66668 25.3998 5.66668 24.6665C5.66668 23.9332 5.92801 23.3052 6.45068 22.7825C6.97245 22.2607 7.60001 21.9998 8.33334 21.9998C9.06668 21.9998 9.69423 22.2607 10.216 22.7825C10.7387 23.3052 11 23.9332 11 24.6665C11 25.3998 10.7387 26.0278 10.216 26.5505C9.69423 27.0723 9.06668 27.3332 8.33334 27.3332ZM21.6667 27.3332C20.9333 27.3332 20.3058 27.0723 19.784 26.5505C19.2613 26.0278 19 25.3998 19 24.6665C19 23.9332 19.2613 23.3052 19.784 22.7825C20.3058 22.2607 20.9333 21.9998 21.6667 21.9998C22.4 21.9998 23.028 22.2607 23.5507 22.7825C24.0725 23.3052 24.3333 23.9332 24.3333 24.6665C24.3333 25.3998 24.0725 26.0278 23.5507 26.5505C23.028 27.0723 22.4 27.3332 21.6667 27.3332ZM8.33334 20.6665C7.33334 20.6665 6.57779 20.2274 6.06668 19.3492C5.55557 18.4718 5.53334 17.5998 6.00001 16.7332L7.80001 13.4665L3.00001 3.33317H1.63334C1.25557 3.33317 0.944455 3.20517 0.70001 2.94917C0.455566 2.69406 0.333344 2.37761 0.333344 1.99984C0.333344 1.62206 0.461343 1.30517 0.717344 1.04917C0.972455 0.794059 1.2889 0.666504 1.66668 0.666504H3.83334C4.07779 0.666504 4.31112 0.733171 4.53334 0.866504C4.75557 0.999837 4.92223 1.18873 5.03334 1.43317L5.93334 3.33317H25.6C26.2 3.33317 26.6111 3.55539 26.8333 3.99984C27.0556 4.44428 27.0445 4.91095 26.8 5.39984L22.0667 13.9332C21.8222 14.3776 21.5 14.7221 21.1 14.9665C20.7 15.2109 20.2445 15.3332 19.7333 15.3332H9.80001L8.33334 17.9998H23.0333C23.4111 17.9998 23.7222 18.1274 23.9667 18.3825C24.2111 18.6385 24.3333 18.9554 24.3333 19.3332C24.3333 19.7109 24.2053 20.0274 23.9493 20.2825C23.6942 20.5385 23.3778 20.6665 23 20.6665H8.33334Z"
                                                                fill="#0E0E0E"/>
                                                        </svg>

                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                )

                            )
                        }

                    </div>
                </div>
            </div>

        </>
    )
}
export {Favorit}