import { Link } from "react-router-dom"
import "../../../scss/menu.scss"
import "../../../scss/responsive/menu.scss"
import { useState, useEffect, useRef } from "react";

import down from "../../../images/drop.svg"
import burger from "../../../images/burger.png"
import close from "../../../images/close.png"
import logo from "../../../images/logoabio.png"
import iconCloseSerch from "../../../images/closeSerch.png"
import iconePhone from "../../../images/iconePhone.png"
import catalogdown from "../../../images/catalogdown.svg"
import leftDown from "../../../images/leftDown.svg"
import I from "../../../images/i.png"
import iconSerch from "../../../images/iconSerch.png"
import {shopData} from "../../../data/shopData"
import { useTranslation } from "react-i18next";

import Dropdownsecond from "./Dropdownsecond";

const MenuCatalog = ({ items, depthLevel,handleActive }) =>{

    const [dropdown, setDropdown] = useState(false);
    const [ openBurger, setOpenBurger]= useState(false)

    let ref = useRef();
    let refff= useRef()
    const {t, i18n} = useTranslation()
   
  
    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
        
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("click", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("click", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);


  
    useEffect(() => {
       handleActive= (event) => {
        if (openBurger && refff.current && !refff.current.contains(event.target)) {
          setOpenBurger(!openBurger);
        }
      };
     
    }, [openBurger]);
    

    const onMouseEnter = () => {
   

        setDropdown(true);
      
      
    };
  
    const onMouseLeave = () => {
    
       setDropdown(false);
    };

    return (
      <li
      className="cat_item"
        ref={ref}
        // onMouseEnter={onMouseEnter}
        // onMouseLeave={onMouseLeave}
       
      > 
        {items?.children?.length ? (
          <>
                 
                 <div className="category-item"  onClick={() => setDropdown((prev) => !prev)}> <p  className="activeIndoor "> {items.name} 
                 <img className="imgdown someClass" src={down} alt="image"  /></p> </div> 
        
            {" "}
            
            <Dropdownsecond
              depthLevel={depthLevel}
              submenus={items.children}
              dropdown={dropdown}
              handleActive={handleActive}
            />{" "}
           
          </>
        ) : (
   
           <div className="category-item"  onClick={handleActive}>
       
          <Link   
          className="activeIndoor linkLeft" to={`/menucatalog/${items.full_name.replace(/\s+/g, '-').toLowerCase()}/${items.code}/${i18n.language}`} >
            {items.name} </Link>
            </div>
        
        
          
        )}
        
      </li>
    );



}
export default MenuCatalog