import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./container/locales/i18next"
import { BrowserRouter as Router} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
          <Suspense fallback={<div>Loading...</div>}>
              <App />
          </Suspense>
      </Router>
  </React.StrictMode>
);

reportWebVitals();
