import OnlineImg from "../../../images/Online.svg"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Link} from "react-router-dom";
import {Cookies} from "react-cookie";

const instance = axios.create({
    withCredentials: true
})

const Online = () => {

    const [addHeader, setaddHeaders] = useState()

    let url = process.env.REACT_APP_BASE_URL;


    const {t, i18n} = useTranslation()


    const fetchCatalogPosts = async (lng) => {
        let lang = lng.slice(0, 2)

        await instance.get(`${url}/abio/public/get/product?`,
            {
                params: {
                    productCode: 888001,
                    language: lang
                }
            }
        )
            .then(res => setaddHeaders(res.data))
            .catch(er => console.log(er))


    }


    useEffect(() => {
        fetchCatalogPosts(i18n.language)

    }, [i18n.language]);


    const cookies = new Cookies();
    const onAdd = async (prod) => {
        await instance.post(`${url}/abio/public/cart/addProduct`, {},
            {
                headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
                params: {
                    productCode: prod,
                    quantity: 1
                }
            }).catch(er => console.log(er));
    };

    return (
        <>
            <p className="services__global">{t("ServicesOnline.online")}</p>

            <div className="services__info">
                <div className="services__img"><img src={OnlineImg} alt="Online"/></div>
                <div className="services__txt">
                    <p>{t("ServicesOnline.request")}</p>
                    <p>{t("ServicesOnline.determine")}</p>
                    <p>{t("ServicesOnline.care")}</p>
                    <p>{t("ServicesOnline.case")}</p>
                    <p>{t("ServicesOnline.forOnline")}</p>

                    <strong>{t("ServicesOnline.cost")} {addHeader?.price ? addHeader?.price : 600} AMD</strong>


                </div>
                <div>
                    <Link to='/tabs'>
                        <button className="services__btn" onClick={() => onAdd(addHeader?.productCode)}>
                            {t("ServicesOnline.btnService")}
                        </button>
                    </Link>
                </div>
            </div>

        </>
    )
}
export {Online}