import "../../scss/contact.scss"
import "../../scss/responsive/contact.scss"
import contactPhone from "../../images/contactPhone.svg"
import contactLocation from "../../images/contactLocation.svg"
import contactEmail from "../../images/contactEmail.svg"
import {useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import map from "../../images/map.png"
import {PinkArea} from "../home/pages/PinkArea"
import {t} from "i18next"
import {useTranslation} from "react-i18next";
import TextField from '@mui/material/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom'

import axios from "axios";


const instance = axios.create({
    withCredentials: true
})

const useStyles = makeStyles({
    root: {

        "& label.Mui-focused": {
            color: "#113E2E"
        },
        "& .MuiOutlinedInput-input": {
            color: "#113E2E"
        },


        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#113E2E"
            }
        }
    }
});

const Contact = ({toggleTab}) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [phone, setPhone] = useState(374)
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Zա-ֆԱ-Ֆаа-яА-Я\s\-]+$/;
    let url = process.env.REACT_APP_BASE_URL;

    const handlePhoneCh = event => {
        const result = event.target.value.replace(/\D/g, '');

        setPhone(result);
    };


    const {
        register,
        handleSubmit,
        reset,

        formState: {errors}
    } = useForm()
    const classes = useStyles();

    const onSubmit = async (data) => {
        // console.log(data)

        reset()
        // navigate('');


        const baseurl = `${url}/abio/public/contact/send`
        await instance.post(baseurl, {...data}, {
            params: {
                fullName: data.fullName,
                mobileNumber: data.mobileNumber,
                email: data.email,
                comment: data.comment
            }
        })
    };


    return (
        <>
            <div className="container">

                <div className="gift__top">
                    <p>{t("giftCard.home")} /<span className="buyGift__topGreen">{t("contact.contact")}</span></p>
                </div>
            </div>
            <div className="contacts">

                <p className="global__contact">{t("contact.contact")}</p>
                <div className="contact__info">
                    <div className="contact__info-block">
                        <div className="contact__info-icon">
                            <img src={contactPhone} alt="contactPhone"/>
                            <a href="tel:+37499520223">+374 99 520223</a>
                            <p>(WhatsApp, Viber)</p>
                        </div>
                        <div className="contact__info-icon">
                            <img src={contactLocation} alt="contactLocation"/>
                            <a>{t("contact.location")}</a>
                        </div>
                        <div className="contact__info-icon">
                            <img src={contactEmail} alt="contactEmail"/>
                            <a href="mailto: info@abio.am">info@abio.am</a>

                        </div>
                    </div>
                    <div className="contact__info-input">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="contact__block">

                                <TextField sx={{minWidth: 250}}
                                           variant="outlined"
                                           label={t("cart.name")}
                                           autoComplete="off"
                                           className={classes.root}
                                           onKeyDown={(event) => {
                                               if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                                   event.preventDefault();
                                               }
                                           }}
                                           {...register("fullName", {required: t("cart.errName")})}
                                           error={!!errors?.fullName}
                                           helperText={errors?.fullName ? errors?.fullName.message : null}


                                />

                            </div>
                            <div className="contact__block">
                                <TextField
                                    sx={{minWidth: 250}}
                                    className={classes.root}
                                    label={t("cart.email")}
                                    placeholder={t("cart.email")}
                                    variant="outlined"
                                    autoComplete="off"
                                    {...register("email", {
                                            required: t("cart.errEmail"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t("cart.errEmail")

                                            }
                                        }
                                    )}
                                    error={!!errors?.email}
                                    helperText={errors?.email ? errors?.email.message : null}/>

                            </div>
                            <div className="contact__block">

                                <TextField
                                    sx={{minWidth: 250}}
                                    label={t("cart.phone")}
                                    className={classes.root}
                                    variant="outlined"
                                    inputProps={{maxLength: 16, minLength: 12}}
                                    autoComplete="off"
                                    // type="number"
                                    placeholder="+374XXXXXXXX"


                                    {...register("mobileNumber", {
                                        required: t("cart.errPhone"),
                                        minLength: 11,
                                        pattern: {

                                            value: /^\+?[0-9]{3}-?[0-9]{8,12}$/,
                                            message: 'Please enter number'
                                        }
                                    })}

                                    error={!!errors?.mobileNumber}
                                    helperText={errors?.mobileNumber ? errors?.mobileNumber.message : null}
                                    value={'+' + phone}
                                    onChange={handlePhoneCh}

                                />
                            </div>
                            <div className="contact__block">
                                <TextField
                                    sx={{minWidth: 250}}

                                    autoComplete="off"
                                    className={classes.root}
                                    placeholder={t("contact.comment")}
                                    minRows={3}
                                    multiline
                                    {...register("comment", {
                                            required: t("cart.comment"),
                                            pattern: {

                                                message: 'Please add a comment'

                                            }
                                        }
                                    )}
                                    error={!!errors?.comment}
                                    helperText={errors?.comment ? errors?.comment.message : null}
                                />

                            </div>
                            <div className="contact__btn">
                                <button type="submit">{t("contact.send")}</button>
                            </div>
                        </form>

                    </div>

                </div>
                <div className="map__contact">
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12178.577477482393!2d44.5980607!3d40.2614342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa1fb02482283%3A0x339ad77fd03f3065!2sABIO%20Garden%20Center!5e0!3m2!1shy!2sam!4v1677585387911!5m2!1shy!2sam"
                            frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false"
                            tabIndex="0"></iframe>


                    </div>
                </div>

                <div className="bottom">
                    <PinkArea/>

                </div>
            </div>


        </>
    )
}

export {Contact}