import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';



i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "am",
    supportedLngs: ['am', 'en', 'ru'],
    lng: localStorage.getItem('i18nextLng') || 'am',
    detection: {
      order: ["path","cookei","localStorage","htmlTag"]
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
   
   
   react: {useSuspense: false}
  });

  export default i18n;