import React from 'react'
import {useState, useEffect} from "react";


import {Link} from "react-router-dom";
import '../../scss/tabs.scss'

import {useTranslation} from "react-i18next";
import axios from 'axios';


import {useLocation} from 'react-router-dom';
import {Cookies} from "react-cookie";


const Fail = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const edpBillNo = queryParams.get('EDP_BILL_NO');
    let url = process.env.REACT_APP_BASE_URL;

    const instance = axios.create({
        withCredentials: true
    })
    const [order, setOrder] = useState('')
    const [email, setEmail] = useState('')
    const {t, i18n} = useTranslation()

    const cookies = new Cookies();
    const getOrderInfo = async () => {
        await instance.get(`${url}/abio/public/cart/resetOrder`, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
            params: {
                orderId: edpBillNo
            }
        }).then(response => {
            setOrder(response.data.id)
            setEmail(response.data.email)
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        getOrderInfo()
    }, []);

    return (
        <div className='succes'>

        
            <div className="errortext">{t("Tabs.fail")} {order} {t("Tabs.fail1")}   </div>

            <div className="errortext">{t("Tabs.faildetail2")} {email} {t("Tabs.faildetail3")}   </div>
            <Link to={`/${i18n.language}`}>
                <button className="continuShop">{t("shop.continue")}</button>
            </Link>


        </div>
    )
}

export {Fail}
