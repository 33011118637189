import React, {useRef, useState, useEffect} from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../scss/recom.scss"
import "../../../scss/responsive/recom.scss"
import {Pagination, Navigation} from "swiper";
import axios from "axios";
import {useTranslation} from "react-i18next";

import row from "../../../images/line.png"

import shop from "../../../images/shop.svg"
import {recomendateData} from "../../../data/recomendateData"
import {Cookies} from "react-cookie";


const instance = axios.create({
    withCredentials: true
})

const GetRecomended = () => {
    const {t, i18n} = useTranslation()

    let url = process.env.REACT_APP_BASE_URL;

    const [rec, setRec] = useState([])


    const [cartItems, setCartItems] = useState(() => {
        const ls = localStorage.getItem("bookmarks");
        if (ls) return JSON.parse(ls);
        else return [];
    });


    useEffect(() => {

        localStorage.setItem("bookmarks", JSON.stringify(cartItems));

    }, [cartItems]);
    useEffect(() => {
        setCartItems(JSON.parse(localStorage.getItem("bookmarks")) || [])

    }, [localStorage.getItem("bookmarks")]);


    const favadd = (product) => {
     
        const exist = cartItems.find((x) => x.productCode === product.productCode);
        if (exist) {
            setCartItems(
                cartItems.map((x) =>
                    x.productCode === product.productCode ? {...exist} : x
                )
            );
        } else {
            setCartItems([...cartItems, {...product}]);
        }
       

    }

    function handleAnim(event) {
        // event.currentTarget.classList.remove('shopCart')
        event.currentTarget.classList.add('shopCart')


    }

 

    useEffect(() => {
        const isCanseled = axios.CancelToken.source();
        let lang = i18n.language.slice(0, 2)
        instance.get(`${url}/abio/public/getRecommendedProducts`, {
                params: {
                    language: lang,
                    page: 0,
                    size: 50
                },
                isCanseled: isCanseled.token
            }
        ).then(response => {
                setRec(response.data)
            }
        ).catch(er => console.log(er));
        return () => {
            isCanseled.cancel()
        }

        // getData();
    }, [i18n.language]);

    const cookies = new Cookies();
    const onAdd = async (prod) => {
        await instance.post(`${url}/abio/public/cart/addProduct`, {},
            {
                headers: {'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S')},
                params: {
                    productCode: prod,
                    quantity: 1
                }
            }).catch(er => console.log(er));
    };


    const handleChartClick = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('favestay')
    }


    return (
        <>


            <p className="recommend">{t("Home.recomended")}</p>
            <div className="container">
                <div className="homeSwiper-container ">
                    {/* <p className="viewAll">{t("Home.all")}</p> */}

                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            744: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                        }}


                        loop={true}
                        // loopFillGroupWithBlank={true}

                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper sliderRec rec"
                        id="recSlide"
                    >

                        {rec?.map((item) =>

                            (
                                <SwiperSlide key={item?.productCode} className="rec-card ">


                                    <svg className="favoritSvg"
                                         onMouseDown={handleChartClick}
                                         onClick={() => favadd(item)}
                                         width="27" height="27" viewBox="0 0 27 27" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect width="27" height="27" rx="10" fill="#FDFDFF"   />
                                        <path
                                            d="M13.0772 21.0635L6.34668 14.333C4.5542 12.5405 4.50276 9.72478 6.1484 8.07914L6.14856 8.07898C7.79312 6.43292 10.6087 6.48473 12.4022 8.27742C12.4023 8.27745 12.4023 8.27747 12.4023 8.2775L12.7245 8.59973L13.0781 8.95329L13.4316 8.59973L13.7539 8.2775C13.7539 8.27747 13.7539 8.27745 13.754 8.27742C15.5223 6.50988 18.2843 6.4347 19.9378 8.00996L20.0069 8.07906C21.6517 9.72469 21.6003 12.5404 19.8071 14.3329L19.807 14.333L13.0772 21.0635Z"
                                            stroke="#0E0E0E"/>
                                    </svg>
                                    <Link className="product__link getprod_link"
                                          to={`/catalog/${item?.catalogs?.slice(1, item?.catalogs?.indexOf('/')).replace(/\s+/g, '-').toLowerCase()}/${item?.productCode}/${i18n.language}`}>
                                        <img className="product-img" alt={item?.pictureIds[0]?.[`alt_${i18n.language}`]}
                                             src={`${url}/abio/public/files?productCode=${item?.productCode}&fileName=${item?.pictureIds[0]?.pictureName}`}/>
                                    </Link>
                                    <div className="rec__info">

                                        <div className="prodquantity">
                                            <p className="rec_name">{item?.name}</p>

                                        </div>

                                        <div className="rec-colorsSlide">
                                            {item?.colorCodes?.length > 0 ? item?.colorCodes.map((el, ind) => {
                                                    return (
                                                        <>
                                                            <div className={el} key={ind}></div>

                                                        </>
                                                    )
                                                }
                                            ) : null}


                                        </div>

                                        <div className="rec-price price__rec">
                                            <div className="price swiper__price">

                                                <p className="new-price"> {item?.discount === 0 ? item?.price.toLocaleString() : item?.discountPrice.toLocaleString()} AMD</p>
                                                <p className="old-price">{item?.discount > 0 ? item?.price.toLocaleString() : null} {item?.discount > 0 ? 'AMD' : null}</p>
                                            </div>


                                            {/* <img onClick={() => onAdd(item.productCode)}
                                                 src={shop} className="shopcart" alt="shop"/> */}

                                            <svg onClick={() => onAdd(item.productCode)} onMouseDown={handleAnim}
                                                 className="shopcart" width="27" height="27" viewBox="0 0 27 27"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27" height="27" rx="10" fill="#FDFDFF"/>
                                                <path
                                                    d="M7.875 24.75C7.25625 24.75 6.72675 24.5299 6.2865 24.0896C5.8455 23.6486 5.625 23.1187 5.625 22.5C5.625 21.8813 5.8455 21.3514 6.2865 20.9104C6.72675 20.4701 7.25625 20.25 7.875 20.25C8.49375 20.25 9.02325 20.4701 9.4635 20.9104C9.9045 21.3514 10.125 21.8813 10.125 22.5C10.125 23.1187 9.9045 23.6486 9.4635 24.0896C9.02325 24.5299 8.49375 24.75 7.875 24.75ZM19.125 24.75C18.5063 24.75 17.9767 24.5299 17.5365 24.0896C17.0955 23.6486 16.875 23.1187 16.875 22.5C16.875 21.8813 17.0955 21.3514 17.5365 20.9104C17.9767 20.4701 18.5063 20.25 19.125 20.25C19.7437 20.25 20.2736 20.4701 20.7146 20.9104C21.1549 21.3514 21.375 21.8813 21.375 22.5C21.375 23.1187 21.1549 23.6486 20.7146 24.0896C20.2736 24.5299 19.7437 24.75 19.125 24.75ZM6.91875 6.75L9.61875 12.375H17.4937L20.5875 6.75H6.91875ZM7.875 19.125C7.03125 19.125 6.39375 18.7545 5.9625 18.0135C5.53125 17.2732 5.5125 16.5375 5.90625 15.8063L7.425 13.05L3.375 4.5H2.22188C1.90313 4.5 1.64062 4.392 1.43437 4.176C1.22812 3.96075 1.125 3.69375 1.125 3.375C1.125 3.05625 1.233 2.78887 1.449 2.57287C1.66425 2.35762 1.93125 2.25 2.25 2.25H4.07812C4.28437 2.25 4.48125 2.30625 4.66875 2.41875C4.85625 2.53125 4.99688 2.69063 5.09063 2.89688L5.85 4.5H22.4438C22.95 4.5 23.2969 4.6875 23.4844 5.0625C23.6719 5.4375 23.6625 5.83125 23.4563 6.24375L19.4625 13.4437C19.2562 13.8187 18.9844 14.1094 18.6469 14.3156C18.3094 14.5219 17.925 14.625 17.4937 14.625H9.1125L7.875 16.875H20.2781C20.5969 16.875 20.8594 16.9826 21.0656 17.1979C21.2719 17.4139 21.375 17.6812 21.375 18C21.375 18.3188 21.267 18.5858 21.051 18.801C20.8358 19.017 20.5688 19.125 20.25 19.125H7.875ZM9.61875 12.375H17.4937H9.61875Z"
                                                    fill="#0E0E0E"/>
                                            </svg>

                                        </div>
                                    </div>


                                </SwiperSlide>


                            ))}


                    </Swiper>
                </div>
            </div>
        </>
    )
}
export {GetRecomended}