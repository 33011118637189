import React from 'react'
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import '../../scss/tabs.scss'
import logo from "../../images/logoabio.png"

const NotFound = () => {
    const {t, i18n} = useTranslation()

    return (
        <div className='succes'>
            <div className='modalCont'>
                <img src={logo} alt="serch" className="logoImg"/>
                <h2 className='errortext'>{t("Tabs.thankserror")}</h2>
            </div>

            <Link to={`/${i18n.language}`}>
                <button className="continuShop">{t("shop.continue")}</button>
            </Link>
        </div>
    )
}

export {NotFound}
